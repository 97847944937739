// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-details-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.back-button {
  color: #6c757d;
  text-decoration: none;
  transition: color 0.2s;
}

.back-button:hover {
  color: #0d6efd;
}

.action-buttons {
  display: flex;
  gap: 0.75rem;
}

.white-space-pre {
  white-space: pre-wrap;
  line-height: 1.6;
}

.map-container {
  background: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
  height: calc(100% - 2rem);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 768px) {
  .location-details-container {
    padding: 1rem;
  }
  
  .header-bar {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .map-container {
    height: 400px;
    margin-top: 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/locationDetails.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,yBAAyB;EACzB,mDAAmD;AACrD;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;AACF","sourcesContent":[".location-details-container {\n  max-width: 1400px;\n  margin: 0 auto;\n  padding: 2rem 1.5rem;\n}\n\n.header-bar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 2rem;\n}\n\n.back-button {\n  color: #6c757d;\n  text-decoration: none;\n  transition: color 0.2s;\n}\n\n.back-button:hover {\n  color: #0d6efd;\n}\n\n.action-buttons {\n  display: flex;\n  gap: 0.75rem;\n}\n\n.white-space-pre {\n  white-space: pre-wrap;\n  line-height: 1.6;\n}\n\n.map-container {\n  background: #f8f9fa;\n  border-radius: 0.5rem;\n  padding: 1rem;\n  height: calc(100% - 2rem);\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);\n}\n\n@media (max-width: 768px) {\n  .location-details-container {\n    padding: 1rem;\n  }\n  \n  .header-bar {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 1rem;\n  }\n  \n  .map-container {\n    height: 400px;\n    margin-top: 1.5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
