import React, { useState } from 'react';
import styles from "../styles/Visitor.module.css";
import { Visitor as VisitorModel } from "../models/visitor";
import { Location } from "../models/location";
import { formatDate } from "../utils/formatDate";
import { MdDelete, MdEdit, MdKey } from "react-icons/md";
import LocationAssignmentDialog from './LocationAssignmentDialog';

interface VisitorProps {
  visitor: VisitorModel;
  onVisitorClicked: (visitor: VisitorModel) => void;
  onDeleteVisitorClicked: (visitor: VisitorModel) => void;
  onEditVisitorClicked: (visitor: VisitorModel) => void;
  onLocationAssigned?: (visitorId: string, locationId: string, roomIds: string[], startDate: Date, endDate: Date) => void;
  locations?: Location[];
  className?: string;
}

const Visitor = ({ 
  visitor, 
  onVisitorClicked, 
  onDeleteVisitorClicked, 
  onEditVisitorClicked, 
  onLocationAssigned,
  locations,
  className 
}: VisitorProps) => {
  const { firstName, lastName, email, description, createdAt, updatedAt, photo } = visitor;
  const [showLocationDialog, setShowLocationDialog] = useState(false);

  const createdUpdateText = updatedAt > createdAt
    ? "Updated: " + formatDate(updatedAt)
    : "Created: " + formatDate(createdAt);

  const handleLocationAssign = (locationId: string, roomIds: string[], startDate: Date, endDate: Date) => {
    onLocationAssigned?.(visitor._id, locationId, roomIds, startDate, endDate);
    setShowLocationDialog(false);
  };

  return (
    <>
      <div 
        className={`${styles.visitorCard} ${className}`}
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (!target.closest(`.${styles.iconContainer}`) && 
              !target.closest(`.${styles.emailLink}`)) {
            onVisitorClicked(visitor);
          }
        }}
      >
        <div className={styles.cardContent}>
          <div className={`${styles.avatarContainer} ${photo ? styles.hasPhoto : ''}`}>
            {photo && (
              <img 
                src={`data:image/jpeg;base64,${photo}`} 
                alt={`${firstName} ${lastName}`} 
                className={styles.photoImage} 
              />
            )}
          </div>

          <div className={styles.cardBody}>
            <div className={styles.cardHeader}>
              <h5 className={styles.nameTitle}>{firstName} {lastName}</h5>
              <div 
                className={styles.iconContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <MdKey
                  className={styles.icon}
                  style={{ color: '#FFC107' }}
                  onClick={() => setShowLocationDialog(true)}
                />
                <MdEdit
                  className={styles.icon}
                  style={{ color: '#0D6EFD' }}
                  onClick={() => onEditVisitorClicked(visitor)}
                />
                <MdDelete
                  className={styles.icon}
                  style={{ color: '#DC3545' }}
                  onClick={() => onDeleteVisitorClicked(visitor)}
                />
              </div>
            </div>
            
            <a 
              href={`mailto:${email}`}
              onClick={(e) => e.stopPropagation()}
              className={styles.emailLink}
            >
              {email}
            </a>

            <div className={styles.descriptionContainer}>
              <p className={styles.description}>
                {description}
              </p>
              <div className={styles.fadeOut} />
            </div>
          </div>
        </div>

        <div className={styles.cardFooter}>
          {createdUpdateText}
        </div>
      </div>

      {/* Dialog rendered outside the card */}
      {showLocationDialog && (
        <div 
          className={styles.dialogOverlay}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowLocationDialog(false);
            }
          }}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <LocationAssignmentDialog 
              locations={locations || []}
              onDismiss={() => setShowLocationDialog(false)}
              onLocationAssigned={handleLocationAssign}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Visitor;