
// Fetch helper function
async function fetchData(input: string, init?: RequestInit) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}${input}`, {
      ...init,
      credentials: "include",
    })
  
    if (response.ok) {
      return response
    } else {
      const errorBody = await response.json().catch(() => ({ error: "Failed to parse error response" }))
      const errorMessage = errorBody.error || "An error occurred"
      console.error(`Fetch error: ${errorMessage}`)
      throw new Error(errorMessage)
    }
  }
  
  // Fetch all logos for a specific user
  export async function fetchLogos(accessToken: string) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/logo`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: "include",
    })
    if (!response.ok) {
      const errorBody = await response.json().catch(() => ({ error: "Failed to parse error response" }))
      throw new Error(`Failed to fetch logos: ${errorBody.error || response.statusText}`)
    }
    return response.json()
  }
  
  // Update the LogoInput interface if it is used elsewhere
  export interface LogoInput {
    themeMode?: string
    primaryColor?: string
    secondaryColor?: string
  }
  
  // Create a new logo
  export async function createLogo(logoData: FormData, accessToken: string) {
    const response = await fetchData("/logo", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: logoData, // FormData automatically sets Content-Type
    })
    return response.json()
  }
  
  // Update an existing logo - FIX: Added logoId parameter to URL path
  export async function updateLogo(logoId: string, logoData: FormData, accessToken: string) {
    // Check if logoId exists before making the request
    if (!logoId) {
      console.error("No logo ID provided for update")
      // Create a new logo instead if no ID exists
      return createLogo(logoData, accessToken)
    }
  
    const response = await fetchData(`/logo/${logoId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: logoData,
    })
  
    return response.json()
  }
  
  // Delete a logo
  export const deleteLogo = async (logoId: string, accessToken: string) => {
    if (!logoId) {
      throw new Error("No logo ID provided for deletion")
    }
  
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/logo/${logoId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: "include",
    })
    if (!response.ok) {
      const errorBody = await response.json().catch(() => ({ error: "Failed to parse error response" }))
      throw new Error(`Failed to delete logo: ${errorBody.error || response.statusText}`)
    }
  }
  
  