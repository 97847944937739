import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import '../styles/navbar.css';
import { FaUserFriends, FaMapMarkerAlt, FaCogs, FaKey, FaSignOutAlt, FaSignInAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { /*useState,*/ useEffect } from 'react';
// import * as LogoAPI from '../network/logo_api';
import { useTheme } from "./ContextTheme";

interface LoggedInUser {
  sub: string;
  email: string;
  name: string;
}

interface NavBarProps {
  loggedInUser: LoggedInUser | null;
  accessToken: string;
}

//let primaryColor = '';

const NavBar = ({ loggedInUser, accessToken }: NavBarProps) => {
    //const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const navigate = useNavigate();
    const { primaryColor, logoUrl } = useTheme();
    

    useEffect(() => {
      const fetchLogo = async () => {
        try {
          //const data = await LogoAPI.fetchLogos(accessToken);
          // if (data[0].logo) {
          //   setLogoUrl(data[0].logo);
          // }
          // primaryColor = data[0].primaryColor;
          console.log(primaryColor);
          
        } catch (error) {
          console.error('Error fetching logo:', error);
        }
      };

      if (accessToken) {
        fetchLogo();
      }
    });

    const handleLogin = () => {
        const returnTo = '/visitors';
        const encodedReturnTo = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}${returnTo}`);
        const loginUrl = `${process.env.REACT_APP_BACKEND_API}/login?returnTo=${encodedReturnTo}`;
        window.location.href = loginUrl;
    };

    const handleLogout = () => {
        window.location.href = `${process.env.REACT_APP_BACKEND_API}/logout`;
    };

    return (
      <Navbar collapseOnSelect variant="dark" expand="lg" sticky="top" className="mb-4 custom-navbar" style={{background:primaryColor}}>
        <Navbar.Brand className="fw-bold text-light">          
          {logoUrl && (
            <img 
              src={`data:image/jpeg;base64, ${logoUrl}`} 
              alt={"Company Logo"}
              style={{ height: '40px', width: 'auto', marginRight: '10px', objectFit: 'contain' }} 
            />
          )}
          {/* { 'Visitor Credential Nexus'} */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate("visitors")} className="nav-item">
              <FaUserFriends className="me-2" /> Visitors
            </Nav.Link>
            <Nav.Link onClick={() => navigate("locations")} className="nav-item">
              <FaMapMarkerAlt className="me-2" /> Locations
            </Nav.Link>
            <NavDropdown title="VC's" id="collapsible-nav-dropdown" className="nav-item">
              <NavDropdown.Item onClick={() => navigate("activeVC")}>
                <FaKey className="me-2" /> Active
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("revokedVC")}>
                <FaKey className="me-2" /> Revoked
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => navigate("settings")} className="nav-item">
              <FaCogs className="me-2" /> Settings
            </Nav.Link>
          </Nav>
          <Nav>
            {loggedInUser ? (
              <NavDropdown title={`Welcome, ${loggedInUser.name}`} id="collapsible-nav-dropdown">
                <NavDropdown.Item onClick={handleLogout}>
                  <FaSignOutAlt className="me-2" /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link onClick={handleLogin} className="nav-item">
                <FaSignInAlt className="me-2" /> Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
};

export default NavBar;
