import { Button, Form, Modal, ModalHeader, ModalTitle, Row, Col } from "react-bootstrap";
import { Location } from "../models/location";
import { useForm, useFieldArray } from "react-hook-form";
import { LocationInput } from "../network/location_api";
import * as LocationApi from "../network/location_api";
import TextInputField from "./form/TextInputField";
import { MdAdd, MdDelete } from "react-icons/md";

interface AddEditLocationDialogProps {
    locationToEdit?: Location,
    onDismiss: () => void,
    onLocationSaved: (location: Location) => void,
}

interface LocationFormInput extends Omit<LocationInput, 'room'> {
    rooms: { value: string }[];
}

const AddEditLocationDialog = ({locationToEdit, onDismiss, onLocationSaved}: AddEditLocationDialogProps) => {
    const {register, handleSubmit, formState: {errors, isSubmitting}, control} = useForm<LocationFormInput>({
        defaultValues: {
            name: locationToEdit?.name || "",
            address: locationToEdit?.address || "",
            rooms: locationToEdit?.room 
                ? (Array.isArray(locationToEdit.room) 
                    ? locationToEdit.room.map(r => ({ value: r }))
                    : [{ value: locationToEdit.room }])
                : [{ value: "" }],
            description: locationToEdit?.description || "",
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "rooms"
    });

    async function onSubmit(input: LocationFormInput) {
        try {
            const locationInput: LocationInput = {
                ...input,
                room: input.rooms.map(r => r.value)
            };

            let locationResponse: Location;
            if (locationToEdit) {
                locationResponse = await LocationApi.updateLocation(locationToEdit._id, locationInput);
            } else {
                locationResponse = await LocationApi.createLocation(locationInput);
            }
            onLocationSaved(locationResponse);
        } catch (error) {
            console.error(error);
            alert("An error occurred while saving the location. Please try again.");
        }
    }

    return (
        <Modal show onHide={onDismiss} size="lg">
            <ModalHeader closeButton>
                <ModalTitle>
                    {locationToEdit ? "Edit location" : "Add location"}
                </ModalTitle>
            </ModalHeader>
            <Modal.Body>
                <Form id="addEditLocationForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="name"
                        label="Location Name"
                        type="text"
                        placeholder="Sandman hotel"
                        register={register}
                        registerOptions={{ required: "Required" }}
                        error={errors.name}
                    />
                    <TextInputField
                        name="address"
                        label="Address"
                        type="text"
                        placeholder="123 Coastal Drive, Beach City, BC V8V 1A1"
                        register={register}
                        registerOptions={{ required: "Required" }}
                        error={errors.address}
                    />

                    <div className="mb-3">
                        <label className="form-label">Rooms</label>
                        {fields.map((field, index) => (
                            <Row key={field.id} className="mb-2 align-items-center">
                                <Col>
                                    <Form.Control
                                        {...register(`rooms.${index}.value`)}
                                        placeholder="Enter room name/number"
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Button 
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => fields.length > 1 && remove(index)}
                                    >
                                        <MdDelete />
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="mt-2"
                            onClick={() => append({ value: "" })}
                        >
                            <MdAdd className="me-1" /> Add Room
                        </Button>
                    </div>

                    <TextInputField
                        name="description"
                        label="Description"
                        as="textarea"
                        rows={5}
                        placeholder="Description"
                        register={register}
                    />      
                    <div className="d-flex justify-content-end">
                        <Button type="submit" disabled={isSubmitting}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditLocationDialog;