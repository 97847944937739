import React, { useCallback, useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface LocationMapProps {
  lat: number;
  lng: number;
  onLocationSelect: (lat: number, lng: number, address?: string) => void;
}

const DEBOUNCE_DELAY = 300;

const LocationMap = ({ lat, lng, onLocationSelect }: LocationMapProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [mapCenter, setMapCenter] = useState<[number, number]>([lat, lng]);
  const mapRef = useRef<L.Map | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);


  const DefaultIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  const MapEvents = () => {
    useMapEvents({
      click: async (e) => {
        const { lat, lng } = e.latlng;
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
          );
          const data = await response.json();
          onLocationSelect(lat, lng, data.display_name);
          setSearchQuery(data.display_name);
          setMapCenter([lat, lng]);
        } catch (error) {
          console.error('Geocoding error:', error);
        }
      },
    });
    return null;
  };

  const debouncedSearch = useCallback(async (query: string) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`
      );
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error('Search error:', error);
    }
  }, []);

  // const handleSearch = useCallback(async (query: string) => {
  //   if (!query) {
  //     setSuggestions([]);
  //     return;
  //   }
  //   try {
  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`
  //     );
  //     const data = await response.json();
  //     setSuggestions(data);
  //   } catch (error) {
  //     console.error('Search error:', error);
  //   }
  // }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      if (query.trim().length > 0) {
        debouncedSearch(query);
      } else {
        setSuggestions([]);
      }
    }, DEBOUNCE_DELAY);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleSuggestionClick = async (suggestion: any) => {
    const lat = parseFloat(suggestion.lat);
    const lng = parseFloat(suggestion.lon);
    setMapCenter([lat, lng]);
    setSearchQuery(suggestion.display_name);
    onLocationSelect(lat, lng, suggestion.display_name);
    mapRef.current?.flyTo([lat, lng], 15);
  };

  return (
    <div className="position-relative">
      <div className="mb-3">
        <input
          type="text"
          placeholder="Search location..."
          className="form-control"
          value={searchQuery}
          onChange={handleInputChange}
        />
        {suggestions.length > 0 && (
          <div className="list-group position-absolute w-100" style={{ zIndex: 10000 }}>
            {suggestions.map((suggestion) => (
              <button
                key={suggestion.place_id}
                type="button"
                className="list-group-item list-group-item-action"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.display_name}
              </button>
            ))}
          </div>
        )}
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        <MapContainer
          center={mapCenter}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={mapCenter} icon={DefaultIcon} />
          <MapEvents />
        </MapContainer>
      </div>
    </div>
  );
};

export default LocationMap;