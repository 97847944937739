import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import Visitor from '../Visitor';
import { FaPlus } from "react-icons/fa";
import * as VisitorsApi from "../../network/visitor_api";
import styles from "../../styles/VisitorsPage.module.css";
import AddEditVisitorDialog from '../AddEditVisitorDialog';
import { Visitor as VisitorModel } from '../../models/visitor';

interface VisitorsPageProps {
  visitors: VisitorModel[];
  accessToken: string;
}

function VisitorsPage({ visitors: initialVisitors, accessToken }: VisitorsPageProps) {
  const [visitors, setVisitors] = useState<VisitorModel[]>(initialVisitors || []);
  const [visitorsLoading, setVisitorsLoading] = useState(true);
  const [showVisitorsLoadingError, setShowVisitorsLoadingError] = useState(false);
  const [showAddVisitorDialog, setShowAddVisitorDialog] = useState(false);
  const [visitorToEdit, setVisitorToEdit] = useState<VisitorModel | null>(null);
  const [sortOrder, setSortOrder] = useState("Most Recently Added");

  useEffect(() => {
    async function loadVisitors() {
      try {
        setVisitorsLoading(true);
        const fetchedVisitors = await VisitorsApi.fetchVisitors(accessToken);
        setVisitors(sortVisitors(fetchedVisitors, sortOrder));
      } catch (error) {
        console.error(error);
        setShowVisitorsLoadingError(true);
      } finally {
        setVisitorsLoading(false);
      }
    }
    if (accessToken) {
      loadVisitors();
    }
  }, [accessToken, sortOrder]);

  const sortVisitors = (visitors: VisitorModel[], order: string) => {
    return [...visitors].sort((a, b) => {
      if (order === "Alphabetical") {
        return a.firstName.localeCompare(b.firstName);
      } else if (order === "Most Recently Added") {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (order === "Least Recently Added") {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      }
      return 0;
    });
  };

  const handleDeleteVisitor = async (visitorId: string) => {
    try {
      await VisitorsApi.deleteVisitor(visitorId, accessToken);
      setVisitors(visitors.filter(visitor => visitor._id !== visitorId));
    } catch (error) {
      console.error("Failed to delete visitor:", error);
      setShowVisitorsLoadingError(true);
    }
  };

  const handleSortChange = (sortOption: string | null) => {
    if (sortOption) {
      setSortOrder(sortOption);
      setVisitors(sortVisitors(visitors, sortOption));
    }
  };

  const visitorsGrid = (
    <Row xs={1} md={2} xl={3} xxl={4} className={`g-4 ${styles.visitorGrid}`}>
      <Col>
        <div
          className={`${styles.visitorCard} ${styles.addVisitorCard}`}
          onClick={() => setShowAddVisitorDialog(true)}
        >
          <FaPlus className={styles.addIcon} />
          <p>Add New Visitor</p>
        </div>
      </Col>
      {visitors.map(visitor => (
        <Col key={visitor._id}>
          <Visitor
            visitor={visitor}
            className={styles.visitor}
            onVisitorClicked={setVisitorToEdit}
            onEditVisitorClicked={setVisitorToEdit}
            onDeleteVisitorClicked={() => handleDeleteVisitor(visitor._id)}
          />
        </Col>
      ))}
    </Row>
  );
  

  return (
    <Container className={styles.visitorsPage}>
      <h1>Registered Visitors</h1>
      {showVisitorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!visitorsLoading && !showVisitorsLoadingError && (
        <>
          {visitors.length > 0 ? (
            <>
              <Dropdown onSelect={handleSortChange} className={styles.sortDropdown}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.dropdownToggle}>
                  Sort: {sortOrder}
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdownMenu}>
                  <Dropdown.Item eventKey="Most Recently Added">Most Recently Added</Dropdown.Item>
                  <Dropdown.Item eventKey="Least Recently Added">Least Recently Added</Dropdown.Item>
                  <Dropdown.Item eventKey="Alphabetical">Alphabetical</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <p></p>
          )}
          {visitorsGrid}
        </>
      )}
      {showAddVisitorDialog && (
        <AddEditVisitorDialog
          accessToken={accessToken}
          onDismiss={() => setShowAddVisitorDialog(false)}
          onVisitorSaved={(newVisitor) => {
            setVisitors(sortVisitors([newVisitor, ...visitors], sortOrder));
            setShowAddVisitorDialog(false);
          }}
        />
      )}
      {visitorToEdit && (
        <AddEditVisitorDialog
          accessToken={accessToken}
          visitorToEdit={visitorToEdit}
          onDismiss={() => setVisitorToEdit(null)}
          onVisitorSaved={(updatedVisitor) => {
            setVisitors(
              sortVisitors(
                visitors.map(existingVisitor =>
                  existingVisitor._id === updatedVisitor._id ? updatedVisitor : existingVisitor
                ),
                sortOrder
              )
            );
            setVisitorToEdit(null);
          }}
        />
      )}
    </Container>
  );  
}

export default VisitorsPage;
