// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingPage_centeredContent__u7qD6 {
    margin-top: 30vh; 
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/LandingPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB","sourcesContent":[".centeredContent {\n    margin-top: 30vh; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centeredContent": `LandingPage_centeredContent__u7qD6`
};
export default ___CSS_LOADER_EXPORT___;
