import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { Visitor } from '../models/visitor';

interface RoomVisitorAssignmentDialogProps {
  roomName: string;
  visitors: Visitor[];
  onDismiss: () => void;
  onAssign: (visitorIds: string[], roomName: string, startDate: Date, endDate: Date) => void;
}

const RoomVisitorAssignmentDialog: React.FC<RoomVisitorAssignmentDialogProps> = ({ 
  roomName, 
  visitors,
  onDismiss, 
  onAssign 
}) => {
  const [selectedVisitors, setSelectedVisitors] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedVisitors.length && startDate && endDate) {
      setIsSubmitting(true);
      try {
        await onAssign(
          selectedVisitors, 
          roomName, 
          new Date(startDate), 
          new Date(endDate)
        );
        onDismiss();
      } catch (error) {
        console.error('Error assigning room:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const visitorOptions = visitors.map(visitor => ({
    value: visitor._id,
    label: `${visitor.firstName} ${visitor.lastName}`
  }));

  const handleVisitorChange = (selectedOptions: any) => {
    const selected = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setSelectedVisitors(selected);
  };

  return (
    <Modal show={true} onHide={onDismiss} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Assign Visitors to Room: {roomName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Select Visitors</Form.Label>
            <Select
              isMulti
              options={visitorOptions}
              onChange={handleVisitorChange}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select visitors..."
              value={visitorOptions.filter(option => selectedVisitors.includes(option.value))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control 
              type="date" 
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control 
              type="date" 
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              min={startDate}
            />
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onDismiss}>
              Cancel
            </Button>
            <Button 
              variant="primary" 
              type="submit"
              disabled={isSubmitting || !selectedVisitors.length || !startDate || !endDate}
            >
              {isSubmitting ? 'Assigning...' : 'Assign Visitors'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RoomVisitorAssignmentDialog;