//Sorry this is actually just a random page, the not logged in page is the NotFound.tsx


import React from 'react';

function NotLoggedInPage() {
  return (
    <div>
      <p >Please log in</p>
    </div>
  );
}

export default NotLoggedInPage;