import { useState } from "react";
import { Button, Form, Modal, ModalHeader, ModalTitle, Row, Col, Image } from "react-bootstrap";
import { Visitor } from "../models/visitor";
import { useForm } from "react-hook-form";
import { VisitorInput } from "../network/visitor_api";
import * as VisitorApi from "../network/visitor_api";
import TextInputField from "./form/TextInputField";
import { FaCamera } from 'react-icons/fa';
import defaultUserIcon from "./pictures/user.svg";

interface AddEditVisitorDialogProps {
    visitorToEdit?: Visitor | null;
    accessToken: string;
    onDismiss: () => void;
    onVisitorSaved: (visitor: Visitor) => void;
}

const AddVisitorDialog = ({ visitorToEdit, accessToken, onDismiss, onVisitorSaved }: AddEditVisitorDialogProps) => {
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<VisitorInput>({
        defaultValues: {
            firstName: visitorToEdit?.firstName || "",
            lastName: visitorToEdit?.lastName || "",
            email: visitorToEdit?.email || "",
            description: visitorToEdit?.description || "",
        }
    });

    const [photo, setPhoto] = useState<File | null>(null);
    const [hovered, setHovered] = useState(false);

    const styles: { [key: string]: React.CSSProperties } = {
        imageWrapper: {
            position: "relative",
        },
        cameraIcon: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: " #89cff0",
            borderRadius: "50%",
            width: "100px",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
        },
        hiddenFileInput: {
            display: "none",
        },
    };
    

    async function onSubmit(input: VisitorInput) {
        const formData = new FormData();
        formData.append("firstName", input.firstName);
        formData.append("lastName", input.lastName);
        formData.append("email", input.email);
        formData.append("description", input.description || "");

        if (photo) {
            formData.append("photo", photo);
        }

        try {
            let visitorResponse: Visitor;
            if (visitorToEdit) {
                visitorResponse = await VisitorApi.updateVisitor(visitorToEdit._id, formData, accessToken);
            } else {
                visitorResponse = await VisitorApi.createVisitor(formData, accessToken);
            }
            onVisitorSaved(visitorResponse);
            onDismiss();
        } catch (error) {
            console.error("Error saving visitor:", error);
            alert("An error occurred while saving the visitor. Please try again.");
        }
    }

    return (
        <Modal show onHide={onDismiss}>
            <ModalHeader closeButton>
                <ModalTitle>{visitorToEdit ? "Edit Visitor" : "Add Visitor"}</ModalTitle>
            </ModalHeader>
            <Modal.Body>
                <Form id="addEditVisitorForm" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="align-items-center">
                    <Col xs="auto" style={{ position: "relative", width: "220px", height: "220px" }} 
                            onMouseEnter={() => setHovered(true)} 
                            onMouseLeave={() => setHovered(false)}
                        >
                            <div style={{ width: "105%", height: "105%", overflow: "hidden", borderRadius: "5%" }}>
                                {photo ? (
                                    <Image
                                        src={URL.createObjectURL(photo)}
                                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        alt="Visitor photo"
                                    />
                                ) : visitorToEdit?.photo ? (
                                    <Image
                                        src={`data:image/jpeg;base64,${visitorToEdit.photo}`}
                                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        alt="Visitor photo"
                                    />
                                ) : (
                                    <Image
                                        src={defaultUserIcon}
                                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        alt="Default user icon"
                                    />
                                )}
                            </div>

                            {hovered && (
                                <div
                                    style={styles.cameraIcon}
                                    onClick={() => document.getElementById("fileInput")?.click()}
                                >
                                    <FaCamera className="text-primary" />
                                </div>
                            )}

                            <Form.Control
                                id="fileInput"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    if (target.files) {
                                        setPhoto(target.files[0]);
                                    }
                                }}
                                style={styles.hiddenFileInput}
                            />
                        </Col>
                        <Col>
                            <TextInputField
                                name="firstName"
                                label="First Name"
                                type="text"
                                placeholder="First Name"
                                register={register}
                                registerOptions={{ required: "Required" }}
                                error={errors.firstName}
                            />
                            <TextInputField
                                name="lastName"
                                label="Last Name"
                                type="text"
                                placeholder="Last Name"
                                register={register}
                                registerOptions={{ required: "Required" }}
                                error={errors.lastName}
                            />
                            <TextInputField
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Email"
                                register={register}
                                registerOptions={{ required: "Required" }}
                                error={errors.email}
                            />
                        </Col>
                    </Row>

                    <TextInputField
                        name="description"
                        label="Description"
                        as="textarea"
                        rows={6}
                        placeholder="Description"
                        register={register}
                    />

                    <Button type="submit" disabled={isSubmitting} className="mt-3">
                        Save
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddVisitorDialog;
