import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Location } from '../models/location';

interface LocationAssignmentDialogProps {
  locations: Location[];
  onDismiss: () => void;
  onLocationAssigned: (locationId: string, roomIds: string[], startDate: Date, endDate: Date) => void;
}

const LocationAssignmentDialog: React.FC<LocationAssignmentDialogProps> = ({ 
  locations, 
  onDismiss, 
  onLocationAssigned 
}) => {
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const availableRooms = locations.find(loc => loc._id === selectedLocation)?.room || [];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedLocation && selectedRooms.length && startDate && endDate) {
      setIsSubmitting(true);
      try {
        await onLocationAssigned(
          selectedLocation, 
          selectedRooms, 
          new Date(startDate), 
          new Date(endDate)
        );
        onDismiss(); // Close the dialog after successful assignment
      } catch (error) {
        console.error('Error assigning location:', error);
        // Optionally add error handling here
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleRoomChange = (roomId: string) => {
    setSelectedRooms(prev => 
      prev.includes(roomId) 
        ? prev.filter(r => r !== roomId) 
        : [...prev, roomId]
    );
  };

  return (
    <Modal show={true} onHide={onDismiss} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Select Location</Form.Label>
            <Form.Control 
              as="select" 
              value={selectedLocation}
              onChange={(e) => {
                setSelectedLocation(e.target.value);
                setSelectedRooms([]); // Reset rooms when location changes
              }}
              required
            >
              <option value="">Choose a location</option>
              {locations.map(location => (
                <option key={location._id} value={location._id}>
                  {location.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {selectedLocation && (
            <Form.Group className="mb-3">
              <Form.Label>Select Rooms</Form.Label>
              {availableRooms.map(room => (
                <Form.Check 
                  key={room}
                  type="checkbox"
                  label={room}
                  checked={selectedRooms.includes(room)}
                  onChange={() => handleRoomChange(room)}
                />
              ))}
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control 
              type="date" 
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control 
              type="date" 
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              min={startDate}
            />
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onDismiss}>
              Cancel
            </Button>
            <Button 
              variant="primary" 
              type="submit"
              disabled={isSubmitting || !selectedLocation || !selectedRooms.length || !startDate || !endDate}
            >
              {isSubmitting ? 'Assigning...' : 'Assign Location'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LocationAssignmentDialog;