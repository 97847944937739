import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import QRCode from 'react-qr-code';
import { Location } from '../../models/location';
import { Visitor } from '../../models/visitor';
import { fetchLocations } from '../../network/location_api';
import { fetchVisitors } from '../../network/visitor_api';
import { fetchVCs, createVC as createVCInDB, VCInput } from '../../network/vc_api';
import { Calendar, User, MapPin, QrCode, X } from 'lucide-react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../../styles/ActiveVCPage.css';

interface VCResponse {
  id: string;
  name: string;
  issueDate: string;
  exchangeId?: string;
  variables?: [{
    var_visitor_name?: string[];
    var_location_name?: string;
    var_rooms?: string[];
    var_start_date?: string;
    var_end_date?: string;
  }];
  urls: {
    protocolsUrl: string;
    exchangeUrl: string;
    subscriptionUrl?: string;
  };
}

interface VisitorOption {
  value: string;
  label: string;
}

interface FilterState {
  visitorName: string;
  location: string;
  room: string;
  date: string;
}

const createVerifiableCredential = async (requestBody: any, accessToken: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/acesConnect/exchanges`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestBody),
      credentials: 'include',
    });

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Received non-JSON response from server");
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Server error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('VC Creation Error:', error);
    throw error instanceof Error ? error : new Error('Failed to create VC');
  }
};

const FilterControls = ({ 
  activeVCs, 
  filters, 
  setFilters, 
  clearFilters 
}: { 
  activeVCs: VCResponse[],
  filters: FilterState,
  setFilters: (filters: FilterState) => void,
  clearFilters: () => void
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  
  const uniqueVisitors = Array.from(new Set(
    activeVCs.flatMap(vc => 
      vc.variables?.[0]?.var_visitor_name?.flat() || []
    )
  ));

  const uniqueLocations = Array.from(new Set(
    activeVCs.map(vc => vc.variables?.[0]?.var_location_name).filter(Boolean)
  ));

  const uniqueRooms = Array.from(new Set(
    activeVCs
      .filter(vc => vc.variables?.[0]?.var_location_name === filters.location)
      .flatMap(vc => vc.variables?.[0]?.var_rooms?.flat() || [])
  ));

  return (
    <div className="filters-section mb-6">
      <button 
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center gap-2 mb-2 text-gray-700 hover:text-gray-900 transition-colors"
      >
        {isExpanded ? (
          <>
            <ChevronUp className="w-4 h-4" />
            Hide Filters
          </>
        ) : (
          <>
            <ChevronDown className="w-4 h-4" />
            Show Filters
          </>
        )}
      </button>

      {isExpanded && (
        <div className="filters-grid gap-6">
          <div className="filter-group space-y-2 pb-2">
            <label className="block text-sm font-medium text-gray-700">Filter by Visitor</label>
            <div className="relative">
              <Select
                value={filters.visitorName ? { 
                  value: filters.visitorName, 
                  label: filters.visitorName 
                } : null}
                //@ts-ignore
                onChange={(option) => setFilters(prev => ({
                  ...prev,
                  visitorName: option?.value || ''
                }))}
                options={uniqueVisitors.map(v => ({
                  value: v,
                  label: v
                }))}
                isClearable
                placeholder="Select visitor..."
                className="filter-select z-50"
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 50
                  })
                }}
              />
            </div>
          </div>

          <div className="filter-group space-y-2 pb-2">
            <label className="block text-sm font-medium text-gray-700">Filter by Location</label>
            <div className="relative">
              <Select
                value={filters.location ? { 
                  value: filters.location, 
                  label: filters.location 
                } : null}
                onChange={(option) => {
                  //@ts-ignore
                  setFilters(prev => ({
                    ...prev,
                    location: option?.value || '',
                    room: ''
                  }));
                }}
                //@ts-ignore
                options={uniqueLocations.map(l => ({
                  value: l,
                  label: l
                }))}
                isClearable
                placeholder="Select location..."
                className="filter-select z-40"
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 40
                  })
                }}
              />
            </div>
          </div>
          
          {filters.location && (
            <div className="filter-group space-y-2 pb-3">
              <label className="block text-sm font-medium text-gray-700">Filter by Room</label>
              <div className="relative">
                <Select
                  value={filters.room ? { 
                    value: filters.room, 
                    label: filters.room 
                  } : null}
                  //@ts-ignore
                  onChange={(option) => setFilters(prev => ({
                    ...prev,
                    room: option?.value || ''
                  }))}
                  options={uniqueRooms.map(r => ({
                    value: r,
                    label: r
                  }))}
                  isClearable
                  placeholder="Select room..."
                  className="filter-select z-30"
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 30
                    })
                  }}
                />
              </div>
            </div>
          )}

          <div className="filter-group space-y-2 pb-1">
            <label className="block text-sm font-medium text-gray-700">Filter by Date</label>
            <div className="input-container">
              <Calendar className="input-icon" />
              <input
                type="date"
                className="form-control"
                value={filters.date}
                //@ts-ignore
                onChange={(e) => setFilters(prev => ({
                  ...prev,
                  date: e.target.value
                }))}
              />
            </div>
          </div>

          <Button
            onClick={clearFilters}
            className="clear-filters-button mt-3 ml-auto mb-4"
          >
            <X className="w-4 h-4 mr-2" />
            Clear Filters
          </Button>
        </div>
      )}
    </div>
  );
};

const ActiveVCPage = ({ accessToken }: { accessToken: string }) => {
  const [activeVCs, setActiveVCs] = useState<VCResponse[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [visitors, setVisitors] = useState<Visitor[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);
  const [selectedVisitors, setSelectedVisitors] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedVC, setSelectedVC] = useState<VCResponse | null>(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [filters, setFilters] = useState<FilterState>({
    visitorName: '',
    location: '',
    room: '',
    date: '',
  });

  const loadInitialData = async () => {
    try {
      setIsLoading(true);
      setError(null);  // Clear any previous errors

      // Add error handling for each API call
      const locationsPromise = fetchLocations(accessToken).catch(err => {
        console.error('Error fetching locations:', err);
        throw new Error('Failed to fetch locations');
      });

      const visitorsPromise = fetchVisitors(accessToken).catch(err => {
        console.error('Error fetching visitors:', err);
        throw new Error('Failed to fetch visitors');
      });

      const vcsPromise = fetchVCs(accessToken).catch(err => {
        console.error('Error fetching VCs:', err);
        throw new Error('Failed to fetch VCs');
      });

      // Wait for all promises to resolve
      const [fetchedLocations, fetchedVisitors, fetchedVCs] = await Promise.all([
        locationsPromise,
        visitorsPromise,
        vcsPromise
      ]);

      // Validate fetched data
      if (!Array.isArray(fetchedLocations)) {
        throw new Error('Invalid locations data received');
      }

      if (!Array.isArray(fetchedVisitors)) {
        throw new Error('Invalid visitors data received');
      }

      if (!Array.isArray(fetchedVCs)) {
        throw new Error('Invalid VCs data received');
      }

      setLocations(fetchedLocations);
      setVisitors(fetchedVisitors);

      const formattedVCs: VCResponse[] = fetchedVCs.map(vc => ({
        id: vc._id || '',  // Add fallback for potentially undefined values
        name: vc.variables?.[0]?.var_location_name || 'Unnamed VC',
        issueDate: vc.createdAt || new Date().toISOString(),
        exchangeId: vc.exchangeId,
        variables: [{
          var_visitor_name: vc.variables?.[0]?.var_visitor_name || [],
          var_location_name: vc.variables?.[0]?.var_location_name || '',
          var_rooms: vc.variables?.[0]?.var_rooms || [],
          var_start_date: vc.variables?.[0]?.var_start_date || '',
          var_end_date: vc.variables?.[0]?.var_end_date || ''
        }],
        urls: {
          protocolsUrl: vc.urls?.protocolsUrl || '',
          exchangeUrl: vc.urls?.exchangeUrl || '',
          subscriptionUrl: vc.urls?.subscriptionUrl
        }
      }));

      setActiveVCs(formattedVCs);
    } catch (error) {
      console.error('Error loading initial data:', error);
      setError(error instanceof Error ? error.message : 'Failed to load data');
      // Set empty arrays for the state variables to prevent undefined errors
      setLocations([]);
      setVisitors([]);
      setActiveVCs([]);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    if (accessToken) {  // Only load data if we have an access token
      loadInitialData();
    } else {
      setError('No access token provided');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);


  const filteredVCs = activeVCs.filter(vc => {
    if (!vc.variables?.[0]) return false;
    
    const vcVars = vc.variables[0];
    
    const visitorMatch = !filters.visitorName || (
      Array.isArray(vcVars.var_visitor_name) && 
      (vcVars.var_visitor_name.flat().includes(filters.visitorName))
    );
    
    const locationMatch = !filters.location || 
      vcVars.var_location_name === filters.location;
    
    const roomMatch = !filters.room || (
      Array.isArray(vcVars.var_rooms) && 
      (vcVars.var_rooms.flat().includes(filters.room))
    );
    
    const dateMatch = !filters.date || (
      vcVars.var_start_date && 
      vcVars.var_end_date && 
      new Date(filters.date) >= new Date(vcVars.var_start_date) && 
      new Date(filters.date) <= new Date(vcVars.var_end_date)
    );
  
    return visitorMatch && locationMatch && roomMatch && dateMatch;
  })
  .sort((a, b) => new Date(b.issueDate).getTime() - new Date(a.issueDate).getTime());

  const clearFilters = () => {
    setFilters({
      visitorName: '',
      location: '',
      room: '',
      date: '',
    });
  };

  const availableRooms = locations.find(loc => loc._id === selectedLocation)?.room || [];

  const visitorOptions: VisitorOption[] = visitors.map(visitor => ({
    value: visitor._id,
    label: `${visitor.firstName} ${visitor.lastName}`
  }));

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedLocation || selectedRooms.length === 0 || !startDate || !endDate || selectedVisitors.length === 0) {
      setError('Please fill in all required fields');
      return;
    }
  
    setIsSubmitting(true);
    try {
      const currentLocation = locations.find(loc => loc._id === selectedLocation);
      if (!currentLocation) throw new Error('Location not found');
  
      const selectedVisitorDetails = visitors.filter(v => selectedVisitors.includes(v._id));
      
      const requestBody = {
        ttl: "3600000",
        variables: {
          var_start_date: new Date(startDate).toISOString(),
          var_end_date: new Date(endDate).toISOString(),
          var_visitor_name: selectedVisitorDetails.map(v => `${v.firstName} ${v.lastName}`),
          var_visitor_emails: selectedVisitorDetails.map(v => v.email),
          var_location_name: currentLocation.name,
          var_location_address: currentLocation.address,
          var_rooms: selectedRooms
        },
      };
  
      const acesResponse = await createVerifiableCredential(requestBody, accessToken);
      console.log('ACES Response:', acesResponse);
  
      if (!acesResponse) {
        throw new Error('Failed to create VC in ACES');
      }
  
      const vcForDB: VCInput = {
        exchangeId: acesResponse.exchangeId,
        sequence: acesResponse.sequence?.toString() || '0',
        ttl: requestBody.ttl,
        variables: [{
          var_start_date: requestBody.variables.var_start_date,
          var_end_date: requestBody.variables.var_end_date,
          var_visitor_name: requestBody.variables.var_visitor_name,
          var_visitor_emails: requestBody.variables.var_visitor_emails,
          var_location_name: requestBody.variables.var_location_name,
          var_location_address: requestBody.variables.var_location_address,
          var_rooms: requestBody.variables.var_rooms
        }],
        state: { type: 'pending' },
        urls: {
          protocolsUrl: acesResponse.urls.protocolsUrl,
          exchangeUrl: acesResponse.urls.exchangeUrl,
          subscriptionUrl: acesResponse.urls.subscriptionUrl,
        }
      };
  
      console.log('VC for DB:', vcForDB);
  
      const storedVC = await createVCInDB(vcForDB, accessToken);
      console.log('Stored VC:', storedVC);
  
      const newVC: VCResponse = {
        id: storedVC._id || '',
        name: currentLocation.name,
        issueDate: new Date().toISOString(),
        urls: vcForDB.urls,
        exchangeId: acesResponse.exchangeId,
        variables: vcForDB.variables
      };
  
      setActiveVCs(prev => [...prev, newVC]);
      handleClose();
    } catch (error) {
      console.error('Error creating VC:', error);
      setError(error instanceof Error ? error.message : 'Failed to create VC');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVisitorChange = (selectedOptions: MultiValue<VisitorOption>) => {
    const selected = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedVisitors(selected);
  };

  const handleRoomChange = (roomId: string) => {
    setSelectedRooms(prev => 
      prev.includes(roomId) 
        ? prev.filter(r => r !== roomId) 
        : [...prev, roomId]
    );

    const currentLocation = locations.find(loc => loc._id === selectedLocation);
    if (currentLocation && !currentLocation.room?.includes(roomId)) {
      setSelectedLocation('');
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedLocation('');
    setSelectedRooms([]);
    setSelectedVisitors([]);
    setStartDate('');
    setEndDate('');
    setError(null);
  };

  const handleVCClick = (vc: VCResponse) => {
    setSelectedVC(vc);
    setShowQRModal(true);
  };

  const handleQRModalClose = () => {
    setShowQRModal(false);
    setSelectedVC(null);
  };

  const customSelectStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: '48px',
      paddingLeft: '24px',
      zIndex: 50
    }),
    container: (base: any) => ({
      ...base,
      width: '100%',
      zIndex: 50
    }),
    //@ts-ignore
    menu: (base) => ({
      ...base,
      zIndex: 50
    })
  };

  if (isLoading) {
    return <div className="loading-container"><div className="loading-spinner"></div></div>;
  }

  return (
    <div className="page-container">
      <div className="content-wrapper">
        <div className="header">
          <h1>Active Verifiable Credentials</h1>
          <button onClick={() => setShowModal(true)} className="create-button">
            <QrCode className="icon" />
            <span>Create New VC</span>
          </button>
        </div>

        <FilterControls 
          activeVCs={activeVCs}
          filters={filters}
          setFilters={setFilters}
          clearFilters={clearFilters}
        />

        <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton className="modal-header">
            <Modal.Title>Create New Verifiable Credential</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Form onSubmit={handleSubmit} className="form-container">
              <Form.Group className="form-group">
                <Form.Label>Location</Form.Label>
                <div className="input-container">
                  <MapPin className="input-icon" />
                  <Form.Control 
                    as="select" 
                    value={selectedLocation}
                    onChange={(e) => {
                      setSelectedLocation(e.target.value);
                      setSelectedRooms([]);
                    }}
                    required
                  >
                    <option value="">Choose a location</option>
                    {locations.map(location => (
                      <option key={location._id} value={location._id}>
                        {location.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>

              {selectedLocation && (
                <Form.Group className="form-group">
                  <Form.Label>Available Rooms</Form.Label>
                  <div className="rooms-grid">
                    {availableRooms.map(room => (
                      <div key={room} className="room-checkbox">
                        <input
                          type="checkbox"
                          id={room}
                          checked={selectedRooms.includes(room)}
                          onChange={() => handleRoomChange(room)}
                        />
                        <label htmlFor={room}>{room}</label>
                      </div>
                    ))}
                  </div>
                </Form.Group>
              )}

              <Form.Group className="form-group">
                <Form.Label>Visitors</Form.Label>
                <div className="input-container">
                  <User className="input-icon" />
                  <Select
                    isMulti
                    options={visitorOptions}
                    onChange={handleVisitorChange}
                    styles={customSelectStyles}
                    placeholder="Select visitors..."
                    value={visitorOptions.filter(option => selectedVisitors.includes(option.value))}
                  />
                </div>
              </Form.Group>

              <div className="date-container">
                <Form.Group className="form-group">
                  <Form.Label>Start Date</Form.Label>
                  <div className="input-container">
                    <Calendar className="input-icon" />
                    <Form.Control 
                      type="date" 
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                    />
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>End Date</Form.Label>
                  <div className="input-container">
                    <Calendar className="input-icon" />
                    <Form.Control 
                      type="date" 
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      required
                      min={startDate}
                    />
                  </div>
                </Form.Group>
              </div>

              {error && <div className="error-message">{error}</div>}

              <div className="form-actions">
                <Button 
                  variant="primary" 
                  type="submit"
                  disabled={isSubmitting || !selectedLocation || !selectedRooms.length || !startDate || !endDate || !selectedVisitors.length}
                  className="submit-button"
                >
                  {isSubmitting ? 'Creating VC...' : 'Create VC'}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showQRModal} onHide={handleQRModalClose} centered>
          <Modal.Header closeButton className="modal-header">
            <Modal.Title>Scan QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body className="qr-modal-body">
            {selectedVC && (
              <>
                <div className="qr-container">
                  <QRCode 
                    value={selectedVC.urls.protocolsUrl}
                    size={256}
                    level="H"
                  />
                </div>
                <p className="qr-description">
                  Scan this QR code to access your verifiable credential
                </p>
                <button 
                  onClick={() => window.open(selectedVC.urls.protocolsUrl, '_blank')}
                  className="open-link-button"
                >
                  Open credential link
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>

        <div className="vc-grid">
          {filteredVCs.map((vc) => (
            <div key={vc.id} className="vc-card" onClick={() => handleVCClick(vc)}>
              <div className="vc-card-content">
                <div>
                  <h2>{vc.name}</h2>
                  <p className="issue-date">
                    Issued: {new Date(vc.issueDate).toLocaleDateString()}
                  </p>
                </div>
                <div className="card-icons">
                  <QrCode className="card-icon" />
                </div> 
              </div>
              <p className="view-qr">Click to view QR code</p>
            </div>
          ))}
        </div>
        
        {filteredVCs.length === 0 && (
          <div className="empty-state">
            <QrCode className="empty-icon" />
            <p>
              {activeVCs.length === 0 
                ? "No active VCs found. Create one by clicking the button above."
                : "No VCs match your current filters. Try adjusting your search criteria."}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveVCPage;