import { Button, Col, Row, Accordion } from "react-bootstrap";
import { Location as LocationModel } from "../models/location";
import { formatDate } from "../utils/formatDate";
import { MdDelete, MdEdit, MdLocationOn, MdRoom } from "react-icons/md";
import '../styles/location.css';

interface LocationProps {
    location: LocationModel,
    onLocationClicked: (location: LocationModel) => void,
    onDeleteLocationClicked: (location: LocationModel) => void,
    className?: string,
}

const Location = ({ location, onLocationClicked, onDeleteLocationClicked, className }: LocationProps) => {
    const { name, address, room, description, createdAt, updatedAt } = location;

    let createdUpdateText: string;
    if (updatedAt > createdAt) {
        createdUpdateText = "Updated: " + formatDate(updatedAt);
    } else {
        createdUpdateText = "Created: " + formatDate(createdAt);
    }

    const rooms = Array.isArray(room) ? room : room ? [room] : [];

    return (
        <Accordion className={`location-accordion ${className}`}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 me-3">
                        <div className="d-flex align-items-center">
                            <MdLocationOn className="me-2" />
                            <span className="fw-bold">{name}</span>
                            <span className="mx-2">-</span>
                            <span>{address}</span>
                        </div>
                        <span className="text-muted small">{createdUpdateText}</span>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col md={12}>
                            <h6 className="mb-3">Rooms:</h6>
                            {rooms.length > 0 ? (
                                <ul className="list-unstyled">
                                    {rooms.map((roomName, index) => (
                                        <li key={index} className="mb-2">
                                            <MdRoom className="me-2" />
                                            {roomName}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-muted">No rooms added yet</p>
                            )}
                            
                            {description && (
                                <>
                                    <h6 className="mb-2 mt-4">Description:</h6>
                                    <p>{description}</p>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="d-flex justify-content-end">
                            <Button
                                variant="outline-primary"
                                className="me-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onLocationClicked(location);
                                }}
                            >
                                <MdEdit className="me-1" /> Edit
                            </Button>
                            <Button
                                variant="outline-danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onDeleteLocationClicked(location);
                                }}
                            >
                                <MdDelete className="me-1" /> Delete
                            </Button>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default Location;