import React from 'react';

function NotLoggedInPage() {
  return (
    <div>
      <p >Please log in</p>
    </div>
  );
}

export default NotLoggedInPage;