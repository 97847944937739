"use client"

import type React from "react"
import { createContext, useContext, useState, useEffect } from "react"

interface ThemeContextType {
  primaryColor: string
  logoUrl: string
  updateTheme: (color: string, logo: string) => void
}

const ThemeContext = createContext<ThemeContextType>({
  primaryColor: "linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%)",
  logoUrl: "",
  updateTheme: () => {},
})

export const useTheme = () => useContext(ThemeContext)

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primaryColor") || "linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%)",
  )
  const [logoUrl, setLogoUrl] = useState(localStorage.getItem("logoUrl") || "")

  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", primaryColor)
  }, [primaryColor])

  const updateTheme = (color: string, logo: string) => {
    setPrimaryColor(color)
    setLogoUrl(logo)
    localStorage.setItem("primaryColor", color)
    localStorage.setItem("logoUrl", logo)
  }

  return <ThemeContext.Provider value={{ primaryColor, logoUrl, updateTheme }}>{children}</ThemeContext.Provider>
}

