import React, { useState, useRef, useCallback} from "react";
import { GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 40.73061,
  lng: -73.935242,
};

const libraries: ("places")[] = ["places"];

const inputStyle: React.CSSProperties = {
  width: "100%",
  padding: "0.5rem",
  marginBottom: "0.5rem",
  border: "1px solid #ccc",
  borderRadius: "0.25rem",
  outline: "none",
  zIndex: 1001,
};

interface LocationMapProps {
  lat: number;
  lng: number;
  initialAddress?: string;
  onLocationSelect: (lat: number, lng: number, address?: string) => void;
}

interface MapMarker {
  lat: number;
  lng: number;
  address?: string;
}

const LocationMap: React.FC<LocationMapProps> = ({ 
  lat, 
  lng, 
  initialAddress,
  onLocationSelect 
}) => {
  const [mapCenter, setMapCenter] = useState({
    lat: lat || defaultCenter.lat,
    lng: lng || defaultCenter.lng,
  });
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [searchBox, setSearchBox] = useState<HTMLInputElement | null>(null);
  //const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  // State to store autocomplete suggestions
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  const handleMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (!event.latLng) return;

      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();

      const newMarker: MapMarker = { lat: newLat, lng: newLng };
      setMarkers([newMarker]);

      if (searchBox) {
        searchBox.value = "Loading address...";
        searchBox.disabled = true;
      }

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { location: { lat: newLat, lng: newLng } },
        (results, status) => {
          if (searchBox) {
            searchBox.disabled = false;
          }

          if (status === "OK" && results?.[0]) {
            const address = results[0].formatted_address;

            if (searchBox) {
              searchBox.value = address;
            }

            setMarkers([{ ...newMarker, address }]);
            onLocationSelect(newLat, newLng, address);
          } else {
            console.error("Geocoding failed: ", status);
            if (searchBox) {
              searchBox.value = "";
            }
            alert("Could not retrieve address for this location");
          }
        }
      );
    },
    [onLocationSelect, searchBox]
  );

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSuggestions([]);
      if (query) {
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: query }, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            //@ts-ignore
            setSuggestions(predictions.map((prediction) => prediction.description));
          }
        });
      }
    },
    []
  );

  const handleSuggestionClick = (address: string) => {
    if (searchBox) {
      searchBox.value = address;
      setSuggestions([]);
    }

    // Geocode the selected address to get lat/lng
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results?.[0]) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        // Update map and marker based on selected suggestion
        setMapCenter({ lat, lng });
        setMarkers([{ lat, lng, address }]);
        onLocationSelect(lat, lng, address);

        if (mapRef.current) {
          mapRef.current.panTo({ lat, lng });
          mapRef.current.setZoom(17);
        }
      } else {
        alert("Could not find the address. Please try again.");
      }
    });
  };

  if (loadError) {
    return (
      <div style={{ padding: "1rem", color: "red" }}>
        Error loading Google Maps. Please check your API key and internet connection.
      </div>
    );
  }

  if (!isLoaded) {
    return <div style={{ padding: "1rem" }}>Loading map...</div>;
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 10,
          width: "calc(100% - 20px)",
          zIndex: 1000,
        }}
      >
        <input
          ref={(input) => setSearchBox(input)}
          type="text"
          style={inputStyle}
          placeholder="Search for a location or click on the map"
          defaultValue={initialAddress}
          onChange={handleInputChange}
        />

        {/* Suggestions dropdown */}
        {suggestions.length > 0 && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              width: "100%",
              backgroundColor: "white",
              border: "1px solid #ccc",
              zIndex: 1001,
            }}
          >
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                style={{ padding: "0.5rem", cursor: "pointer" }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>

      <GoogleMap
        mapContainerStyle={{
          ...containerStyle,
          zIndex: 1,
        }}
        center={mapCenter}
        zoom={15}
        onClick={handleMapClick}
        onLoad={onMapLoad}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: true,
          zoomControl: true,
          clickableIcons: false,
        }}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            animation={google.maps.Animation.DROP}
            draggable={true}
            onDragEnd={(e) => {
              if (e.latLng) {
                handleMapClick(e as google.maps.MapMouseEvent);
              }
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default LocationMap;
