import { Visitor } from "../models/visitor";

// Fetch helper function
async function fetchData(input: string, init?: RequestInit) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}${input}`, {
        ...init,
        credentials: 'include',
    });

    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error || 'An error occurred';
        console.error(`Fetch error: ${errorMessage}`);
        throw new Error(errorMessage);
    }
}

// Fetch all visitors for a specific user
export async function fetchVisitors(accessToken: string) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/visitors`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(`Failed to fetch visitors: ${errorBody.error || response.statusText}`);
    }
    return response.json();
}

// Update the VisitorInput interface if it is used elsewhere
export interface VisitorInput {
    firstName: string;
    lastName: string;
    email: string;
    description?: string;
}

// Update createVisitor to accept FormData
export async function createVisitor(visitorData: FormData, accessToken: string): Promise<Visitor> {
    const response = await fetchData("/visitors", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        body: visitorData, // FormData will set the Content-Type automatically
    });
    return response.json();
}

// Update updateVisitor to accept FormData
export async function updateVisitor(visitorId: string, visitorData: FormData, accessToken: string): Promise<Visitor> {
    const response = await fetchData(`/visitors/${visitorId}`, {
        method: "PATCH",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        body: visitorData,
    });

    if (!response.ok) {
        const errorBody = await response.json();
        console.error(`Update error: ${errorBody.error || response.statusText}`);
        throw new Error(`Error updating visitor: ${errorBody.error || response.statusText}`);
    }

    return response.json();
}

// Delete a visitor
export const deleteVisitor = async (visitorId: string, accessToken: string) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/visitors/${visitorId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(`Failed to delete visitor: ${errorBody.error || response.statusText}`);
    }
};


export async function assignLocation(
    visitorId: string, 
    locationId: string, 
    roomIds: string[], 
    startDate: Date, 
    endDate: Date,
    accessToken: string
): Promise<void> {
    // Log the information instead of sending a request
    console.log('Assigning Location:');

    // If you want to simulate a successful response
    return Promise.resolve();
}
