import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Shield, Users, MapPin } from 'lucide-react';
import '../../styles/LandingPage.css'; // Make sure this path is correct

function LandingPage() {
  const handleLoginRedirect = () => {
    const returnTo = '/visitors';
    const encodedReturnTo = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}${returnTo}`);
    const loginUrl = `${process.env.REACT_APP_BACKEND_API}/login?returnTo=${encodedReturnTo}`;
    window.location.href = loginUrl;
  };

  return (
    <div className="landing-page">
      {/* Hero Section */}
      <section className="hero-section">
        <Card className="hero-card">
          <Card.Body className="text-center">
            <h1 className="hero-title">Visitor Credential Nexus</h1>
            <p className="hero-subtitle">
              Streamline visitor management with our secure, intuitive platform designed for modern organizations.
            </p>
            <Button 
              className="get-started-btn"
              onClick={handleLoginRedirect}
            >
              Get Started <i className="bi bi-arrow-right"></i>
            </Button>
          </Card.Body>
        </Card>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <Container>
          <h2 className="section-title text-center">Our Features</h2>
          <Row className="justify-content-center">
            <Col lg={4} md={6} className="feature-col">
              <Card className="feature-card">
                <Card.Body>
                  <div className="feature-icon-container">
                    <Shield size={32} className="feature-icon" />
                  </div>
                  <Card.Title className="feature-title">Secure Credentials</Card.Title>
                  <Card.Text className="feature-description">
                    Enterprise-grade security ensures visitor information stays protected at all times.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} className="feature-col">
              <Card className="feature-card">
                <Card.Body>
                  <div className="feature-icon-container">
                    <Users size={32} className="feature-icon" />
                  </div>
                  <Card.Title className="feature-title">Visitor Management</Card.Title>
                  <Card.Text className="feature-description">
                    Effortlessly track, manage, and report on all visitor activity across your organization.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} className="feature-col">
              <Card className="feature-card">
                <Card.Body>
                  <div className="feature-icon-container">
                    <MapPin size={32} className="feature-icon" />
                  </div>
                  <Card.Title className="feature-title">Multi-Location Support</Card.Title>
                  <Card.Text className="feature-description">
                    Manage credentials across multiple facilities from a single, unified dashboard.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <Card className="cta-card">
          <Card.Body className="text-center">
            <h2 className="cta-title">Ready to transform your visitor management?</h2>
            <p className="cta-text">
              Join organizations worldwide who trust Visitor Credential Nexus for their security needs.
            </p>
            <Button 
              className="login-dashboard-btn"
              onClick={handleLoginRedirect}
            >
              Login to Dashboard
            </Button>
          </Card.Body>
        </Card>
      </section>

      {/* Footer */}
      <footer className="site-footer">
        <Container>
          <Card className="footer-card">
            <Card.Body className="text-center">
              <p className="copyright">© {new Date().getFullYear()} Visitor Credential Nexus. All rights reserved.</p>
            </Card.Body>
          </Card>
        </Container>
      </footer>
    </div>
  );
}

export default LandingPage;