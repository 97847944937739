import React, { useState, useEffect } from 'react';
import * as LogoAPI from '../../network/logo_api'; // Correct import for logo_api
import { useTheme } from '../ContextTheme';

// Theme modes
const THEME_MODES = ['Light', 'Dark', 'Nexus'];

interface SettingPageProps {
  accessToken: string;
}

// Predefined color palettes
const COLOR_PALETTES = {
  primary: [
    '#2C3E50',  // light gray for light mode background
    '#1c133e',  // clean white for light mode accent
    '#121212',  // very dark gray for dark mode background
    '#1F1F1F',  // dark slate gray for dark mode accent
    '#8897fe',  // light blue for base color
    'linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%)', 
  ],
  secondary: [
    '#FFC107',  
    '#03A9F4',  
    '#E91E63',  
    '#673AB7',  
    '#795548',  
    '#009688',  
  ]
};

const SettingsPage = ({ accessToken }: SettingPageProps) => {
  const { updateTheme } = useTheme();
  const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'Light');
  const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('primaryColor') || 'linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%)');
  const [secondaryColor, setSecondaryColor] = useState(localStorage.getItem('secondaryColor') || '#FFC107');
  const [logoUrl, setLogoUrl] = useState(localStorage.getItem('logoUrl') || '');
  const [logoId, setLogoId] = useState(localStorage.getItem('logoId') || '');

  useEffect(() => {
    document.body.className = `theme-${themeMode.toLowerCase()}`;
    document.documentElement.style.setProperty('--primary-color', primaryColor);
    document.documentElement.style.setProperty('--secondary-color', secondaryColor);
    localStorage.setItem('themeMode', themeMode);
    localStorage.setItem('primaryColor', primaryColor);
    localStorage.setItem('secondaryColor', secondaryColor);
    if (logoUrl) {
      localStorage.setItem('logoUrl', logoUrl);
    } else {
      localStorage.removeItem('logoUrl');
    }

    updateTheme(primaryColor, logoUrl);
    // Save settings whenever they change
    updateSettings();
    
    // eslint-disable-next-line
  }, [themeMode, primaryColor, secondaryColor, logoUrl]);

  // Handle logo file upload
  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert('File is too large. Maximum size is 5MB.');
        return;
      }
  
      const formData = new FormData();
      formData.append('logo', file);
  
      try {
        const data = await LogoAPI.createLogo(formData, accessToken);
        if (typeof data.logo === 'string' && data._id) {
          setLogoUrl(data.logo); // Save logo URL
          setLogoId(data._id);    // Save logo ID
          localStorage.setItem('logoId', data._id); // Persist logo ID
        } else {
          console.error('Unexpected response format: Missing logo URL or ID');
        }
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  // Save settings to the server
  const updateSettings = async () => {
    try {
      const formData = new FormData();
      formData.append('primaryColor', primaryColor);
      formData.append('secondaryColor', secondaryColor);
      formData.append('themeMode', themeMode);

      await LogoAPI.updateLogo(logoId, formData, accessToken);
    } catch (error) {
      console.error('Error saving settings to database:', error);
    }
  };

  // Remove logo
  const removeLogo = async () => {
    if (!logoId) {
      console.error('No logo ID found to delete.');
      return;
    }
  
    try {
      await LogoAPI.deleteLogo(logoId, accessToken);
      setLogoUrl(''); // Clear logo URL
      setLogoId('');  // Clear logo ID
      localStorage.removeItem('logoId'); // Remove logo ID from storage
      localStorage.removeItem('logoUrl'); // Remove logo URL from storage
    } catch (error) {
      console.error('Error removing logo:', error);
    }
  };

  // Reset to default theme
  const resetTheme = () => {
    setThemeMode('Light');
    setPrimaryColor('#1E90FF');
    setSecondaryColor('#FFC107');
    setLogoUrl('');
  };

  return (
    <div style={{ maxWidth: '400px', margin: '40px auto', padding: '30px', backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '30px' }}>Theme Settings</h2>

      {/* Logo Upload Section */}
      <div style={{ marginBottom: '30px' }}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>Logo</h3>
        <input type="file" accept="image/*" onChange={handleLogoUpload} style={{ marginBottom: '15px' }} />
        {logoUrl && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
            <img src={`data:image/png;base64, ${logoUrl}`} alt="Uploaded Logo" style={{ maxWidth: '150px', maxHeight: '150px', marginRight: '15px', background: primaryColor}} />
            <button
              onClick={removeLogo}
              style={{
                padding: '8px 12px',
                backgroundColor: '#FF5722',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              Remove
            </button>
          </div>
        )}
      </div>

      {/* Theme Mode Selection */}
      <div style={{ marginBottom: '30px' }}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>Theme Mode</h3>
        <div style={{ display: 'flex', gap: '10px' }}>
          {THEME_MODES.map((mode) => (
            <button
              key={mode}
              onClick={() => setThemeMode(mode)}
              style={{
                flex: 1,
                padding: '10px 15px',
                backgroundColor: themeMode === mode ? '#1E90FF' : '#f0f0f0',
                color: themeMode === mode ? 'white' : '#7F7E7E',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              {mode} Mode
            </button>
          ))}
        </div>
      </div>

      {/* Primary Color Selection */}
      <div style={{ marginBottom: '30px' }}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>Primary Color</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px' }}>
          {COLOR_PALETTES.primary.map((color) => (
            <button
              key={color}
              onClick={() => setPrimaryColor(color)}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: color,
                border: primaryColor === color ? '3px solid #1E90FF' : 'none',
                cursor: 'pointer',
              }}
            />
          ))}
        </div>
      </div>

      {/* Secondary Color Selection */}
      <div style={{ marginBottom: '30px' }}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>Secondary Color</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px' }}>
          {COLOR_PALETTES.secondary.map((color) => (
            <button
              key={color}
              onClick={() => setSecondaryColor(color)}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: color,
                border: secondaryColor === color ? '3px solid #1E90FF' : 'none',
                cursor: 'pointer',
              }}
            />
          ))}
        </div>
      </div>

      {/* Reset Button */}
      <button
        onClick={resetTheme}
        style={{
          width: '100%',
          padding: '12px 15px',
          backgroundColor: '#1E90FF',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        Reset to Default
      </button>
    </div>
  );
};

export default SettingsPage;
