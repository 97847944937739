import React, { useState, useEffect } from 'react';
import { Button, Container, Dropdown } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import Location from '../Location';
import AddEditLocationDialog from '../AddEditLocationDialog';
import * as LocationsApi from "../../network/location_api";
import { Location as LocationModel } from '../../models/location';
import styles from "../../styles/LocationsPage.module.css";

interface LocationsPageProps {
  accessToken: string;
}

// interface LocationInput {
//   name: string;
//   address: string;
//   room?: string[] | undefined;
//   description?: string;
// }

const SORT_OPTIONS = {
  RECENT: "Most Recently Added",
  LEAST_RECENT: "Least Recently Added",
  ALPHABETICAL: "Alphabetical"
};

const LocationsPage = ({ accessToken }: LocationsPageProps) => {
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [showLocationsLoadingError, setShowLocationsLoadingError] = useState(false);
  const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
  const [locationToEdit, setLocationToEdit] = useState<LocationModel | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState(SORT_OPTIONS.RECENT);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const fetchedLocations = await LocationsApi.fetchLocations(accessToken);
        setLocations(sortLocations(fetchedLocations, sortOrder));
      } catch (error) {
        console.error("Failed to load locations:", error);
        setShowLocationsLoadingError(true);
      }
    };
    fetchLocations();
  }, [accessToken, sortOrder]);

  const sortLocations = (locations: LocationModel[], order: string) => {
    return [...locations].sort((a, b) => {
      if (order === SORT_OPTIONS.ALPHABETICAL) {
        return a.name.localeCompare(b.name);
      } else if (order === SORT_OPTIONS.RECENT) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (order === SORT_OPTIONS.LEAST_RECENT) {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      }
      return 0;
    });
  };

  const handleSortChange = (sortOption: string | null) => {
    if (sortOption) {
      setSortOrder(sortOption);
    }
  };

  const handleLocationClick = (location: LocationModel) => {
    setLocationToEdit(location);
    setShowAddLocationDialog(true);
  };

  const handleDeleteLocation = async (locationId: string) => {
    try {
      await LocationsApi.deleteLocation(locationId);
      setLocations(locations.filter(location => location._id !== locationId));
    } catch (error) {
      console.error("Failed to delete location:", error);
      setShowLocationsLoadingError(true);
    }
  };

  const handleLocationSaved = (updatedLocation: LocationModel) => {
    if (locationToEdit) {
      // Handle edit case
      setLocations(locations.map(location => 
        location._id === updatedLocation._id ? updatedLocation : location
      ));
    } else {
      // Handle add case
      setLocations(prevLocations => sortLocations([updatedLocation, ...prevLocations], sortOrder));
    }
    setShowAddLocationDialog(false);
    setLocationToEdit(undefined);
  };

  return (
    <Container className={styles.locationsPage}>
      <h1>Locations</h1>
      {showLocationsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      
      {locations.length > 0 ? (
        <>
          <Dropdown onSelect={handleSortChange} className={styles.sortDropdown}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={styles.dropdownToggle}>
              Sort: {sortOrder}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              {Object.values(SORT_OPTIONS).map(option => (
                <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <ul className={styles.locationList}>
            {locations.map((location) => (
              <li key={location._id}>
                <Location
                  location={location}
                  onLocationClicked={() => handleLocationClick(location)}
                  onDeleteLocationClicked={() => handleDeleteLocation(location._id)}
                />
              </li>
            ))}
          </ul>
        </>
      ) : <p>No locations available.</p>}

      <Button
        className={`mb-4 ${styles.addLocationButton}`}
        onClick={() => {
          setLocationToEdit(undefined);
          setShowAddLocationDialog(true);
        }}
      >
        <FaPlus /> Add New Location
      </Button>

      {showAddLocationDialog && (
        <AddEditLocationDialog
          locationToEdit={locationToEdit}
          onDismiss={() => setShowAddLocationDialog(false)}
          onLocationSaved={handleLocationSaved}
        />
      )}
    </Container>
  );
};

export default LocationsPage;