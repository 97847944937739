import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { MdDelete, MdEdit, MdLocationOn, MdRoom, MdArrowBack } from "react-icons/md";
//import { GoogleMap, Marker } from "@react-google-maps/api";
import { Location as LocationModel } from "../../models/location";
import * as LocationsApi from "../../network/location_api";
import AddEditLocationDialog from '../AddEditLocationDialog';
import "../../styles/locationDetails.css";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface LocationDetailsPageProps {
    accessToken: string;
  }

  const LocationDetailsPage = ({ accessToken }: LocationDetailsPageProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState<LocationModel | null>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const locationData = await LocationsApi.fetchLocation(id!, accessToken);
        setLocation(locationData);
      } catch (error) {
        setError("Location not found");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchLocation();
  }, [id, accessToken]);

  const handleDelete = async () => {
    try {
      await LocationsApi.deleteLocation(id!, accessToken);
      navigate('/locations');
    } catch (error) {
      console.error("Failed to delete location:", error);
      alert("Failed to delete location");
    }
  };

  const handleLocationUpdate = async (updatedLocation: LocationModel) => {
    try {
      // Convert to LocationInput
      const locationInput: LocationsApi.LocationInput = {
        name: updatedLocation.name,
        address: updatedLocation.address,
        room: updatedLocation.room,
        description: updatedLocation.description,
        lat: updatedLocation.lat,
        lng: updatedLocation.lng
      };

      const result = await LocationsApi.updateLocation(
        updatedLocation._id, 
        locationInput,
        accessToken
      );
      setLocation(result);
      setShowEditDialog(false);
    } catch (error) {
      console.error("Failed to update location:", error);
      alert("Failed to update location");
    }
  };

  const DefaultIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });


  if (isLoading) {
    return <Spinner animation="border" className="d-block mx-auto mt-5" />;
  }

  if (error || !location) {
    return <Alert variant="danger" className="mt-4">{error || "Location not found"}</Alert>;
  }

  return (
    <div className="location-details-container">
      <div className="header-bar mb-4">
        <Button
          variant="link"
          onClick={() => navigate('/locations')}
          className="back-button"
        >
          <MdArrowBack /> Back to Locations
        </Button>
        <div className="action-buttons">
          <Button
            variant="outline-primary"
            onClick={() => setShowEditDialog(true)}
            className="me-2"
          >
            <MdEdit /> Edit
          </Button>
          <Button
            variant="outline-danger"
            onClick={handleDelete}
          >
            <MdDelete /> Delete
          </Button>
        </div>
      </div>

      <div className="location-card bg-white rounded-3 shadow-sm p-4">
        <div className="d-flex justify-content-between align-items-start mb-4">
          <div className="d-flex align-items-center gap-2">
            <MdLocationOn className="text-primary fs-3" />
            <div>
              <h1 className="h2 mb-0 fw-bold text-dark">{location.name}</h1>
              <p className="text-muted mb-0">{location.address}</p>
            </div>
          </div>
        </div>

        <Row className="g-4">
          <Col md={4} className="pe-md-4">
            {location.description && (
              <div className="mb-4">
                <h6 className="text-uppercase text-secondary mb-3">Description</h6>
                <p className="text-dark white-space-pre">{location.description}</p>
              </div>
            )}

            <div className="mb-4">
              <h6 className="text-uppercase text-secondary mb-3">Rooms</h6>
              {location.room?.length ? (
                <ul className="list-unstyled">
                  {location.room.map((roomName, index) => (
                    <li key={index} className="mb-2 d-flex align-items-center">
                      <MdRoom className="me-2 fs-5 text-secondary" />
                      <span className="fw-medium text-secondary">{roomName}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted">No rooms added yet</p>
              )}
            </div>
          </Col>

          {/* Right Column - Map */}
          <Col md={8}>
          {location.lat && location.lng && (
            <div className="map-container h-100">
              <h6 className="text-uppercase text-secondary mb-3">Location Map</h6>
              <div className="rounded-3 overflow-hidden border h-100">
                <MapContainer
                  center={[location.lat, location.lng]}
                  zoom={15}
                  style={{ height: '400px', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[location.lat, location.lng]} icon={DefaultIcon} />
                </MapContainer>
              </div>
            </div>
          )}
          </Col>
        </Row>
      </div>
      {showEditDialog && (
        <AddEditLocationDialog
          accessToken={accessToken}
          locationToEdit={location}
          onDismiss={() => setShowEditDialog(false)}
          onLocationSaved={handleLocationUpdate}
        />
      )}
    </div>
  );
};

export default LocationDetailsPage;