"use client"

import type React from "react"
import { createContext, useContext, useState, useEffect } from "react"

interface ThemeContextType {
  primaryColor: string
  secondaryColor: string
  themeMode: string
  logoUrl: string
  updateTheme: (color: string, logo: string, secondaryColor?: string, themeMode?: string) => void
}

const ThemeContext = createContext<ThemeContextType>({
  primaryColor: "#1E90FF", // Changed from gradient to solid color for better compatibility
  secondaryColor: "#FFC107",
  themeMode: "light",
  logoUrl: "",
  updateTheme: () => {},
})

export const useTheme = () => useContext(ThemeContext)

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  // Use function form of useState for efficient initialization from localStorage
  const [primaryColor, setPrimaryColor] = useState(() => localStorage.getItem("primaryColor") || "#1E90FF")
  const [secondaryColor, setSecondaryColor] = useState(() => localStorage.getItem("secondaryColor") || "#FFC107")
  const [themeMode, setThemeMode] = useState(() => localStorage.getItem("themeMode") || "light")
  const [logoUrl, setLogoUrl] = useState(() => localStorage.getItem("logoUrl") || "")

  // Apply theme settings on initial load and when they change
  useEffect(() => {
    document.body.className = `theme-${themeMode}`

    // Apply colors to CSS variables
    document.documentElement.style.setProperty("--primary-color", primaryColor)
    document.documentElement.style.setProperty("--secondary-color", secondaryColor)

    // Apply dark/light mode class for system-wide styling
    if (themeMode.toLowerCase() === "dark") {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }, [primaryColor, secondaryColor, themeMode])

  // Comprehensive theme update function
  const updateTheme = (
    color: string, 
    logo: string, 
    secondary: string = secondaryColor, 
    mode: string = themeMode
  ) => {
    setPrimaryColor(color);
    setLogoUrl(logo);
    setSecondaryColor(secondary);
    setThemeMode(mode);
  
    // Corrected localStorage setItem calls
    localStorage.setItem("primaryColor", color);
    localStorage.setItem("logoUrl", logo);
    localStorage.setItem("secondaryColor", secondary);
    localStorage.setItem("themeMode", mode); // Fixed this line
  
    // Apply theme mode immediately
    document.body.className = `theme-${mode.toLowerCase()}`;
    
    // Apply CSS variables
    document.documentElement.style.setProperty("--primary-color", color);
    document.documentElement.style.setProperty("--secondary-color", secondary);
  };

  return (
    <ThemeContext.Provider
      value={{
        primaryColor,
        secondaryColor,
        themeMode,
        logoUrl,
        updateTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

