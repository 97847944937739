// Location.tsx (preview version)
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { Location as LocationModel } from "../models/location";
import { Visitor } from "../models/visitor";
import { formatDate } from "../utils/formatDate";
import { MdDelete, /*MdEdit,*/ MdLocationOn, MdRoom, MdExpandMore } from "react-icons/md";
import "../styles/location.css";

interface LocationProps {
  location: LocationModel;
  visitors: Visitor[];
  onDeleteLocationClicked: (location: LocationModel) => void;
  className?: string;
}

const Location = ({
  location,
  visitors = [],
  onDeleteLocationClicked,
  className,
}: LocationProps) => {
  const { _id, name, address, room, description, createdAt, updatedAt } = location;
  const [showAllRooms, setShowAllRooms] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  let createdUpdateText: string;
  if (updatedAt > createdAt) {
    createdUpdateText = "Updated: " + formatDate(updatedAt);
  } else {
    createdUpdateText = "Created: " + formatDate(createdAt);
  }

  const rooms = Array.isArray(room) ? room : room ? [room] : [];
  const visibleRooms = showAllRooms ? rooms : rooms.slice(0, 3);
  const hasMoreRooms = rooms.length > 3;
  const descriptionPreview = description ? description.split('\n').slice(0, 2).join('\n') : '';
  const hasMoreDescription = description && description.split('\n').length > 2;

  const handleViewDetails = () => {
    navigate(`/locations/${_id}`);
  };

  return (
    <div className={`location-card bg-white rounded-3 shadow-sm p-4 mb-4 ${className}`}>
      <div className="d-flex justify-content-between align-items-start mb-3">
        <div className="d-flex align-items-center gap-2">
          <MdLocationOn className="text-primary fs-3" />
          <div>
            <h3 className="h5 mb-0 fw-bold text-dark">{name}</h3>
            <p className="text-muted mb-0">{address}</p>
          </div>
        </div>
        <span className="text-muted small">{createdUpdateText}</span>
      </div>

      {description && (
        <div className="mb-3">
          <div className={`text-dark description-preview ${!showFullDescription ? 'truncated' : ''}`}>
            {showFullDescription ? description : descriptionPreview}
          </div>
          {hasMoreDescription && (
            <Button
              variant="link"
              className="p-0 text-decoration-none"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? "Show less" : "Show more..."}
            </Button>
          )}
        </div>
      )}

      <div className="mb-4">
        <h6 className="text-uppercase text-secondary mb-3">Rooms</h6>
        {rooms.length > 0 ? (
          <>
            <ul className="list-unstyled mb-0">
              {visibleRooms.map((roomName, index) => (
                <li key={index} className="mb-1 d-flex align-items-center">
                  <MdRoom className="me-2 fs-5 text-secondary" />
                  <span className="fw-medium text-secondary">{roomName}</span>
                </li>
              ))}
            </ul>
            {hasMoreRooms && (
              <Button
                variant="link"
                className="text-decoration-none p-0"
                onClick={() => setShowAllRooms(!showAllRooms)}
              >
                {showAllRooms ? "Show less" : `Show more (${rooms.length - 3}+)`}
              </Button>
            )}
          </>
        ) : (
          <p className="text-muted">No rooms added yet</p>
        )}
      </div>

      <div className="d-flex justify-content-between gap-2 pt-3 border-top">
        <Button
          variant="primary"
          onClick={handleViewDetails}
          className="d-flex align-items-center gap-1"
        >
          <MdExpandMore /> View Details
        </Button>
        <div className="d-flex gap-2">
          <Button
            variant="outline-danger"
            className="d-flex align-items-center gap-1"
            onClick={(e) => {
              e.preventDefault();
              onDeleteLocationClicked(location);
            }}
          >
            <MdDelete />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Location;