"use client"

import { useState, useEffect } from "react"

interface VCState {
  exchangeId: string
  state: "pending" | "completed" | "failed"
}

export const useSSE = () => {
  const [vcStates, setVCStates] = useState<Record<string, VCState>>({})
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_API}/vc-updates`)

    eventSource.onopen = () => {
      console.log("SSE connection opened")
      setIsConnected(true)
    }

    eventSource.onmessage = (event) => {
      console.log("Received SSE message:", event.data)
      try {
        const data = JSON.parse(event.data) as VCState
        setVCStates((prev) => ({
          ...prev,
          [data.exchangeId]: data,
        }))
      } catch (error) {
        console.error("Error parsing SSE message:", error)
      }
    }

    eventSource.onerror = (error) => {
      console.error("SSE connection error:", error)
      setIsConnected(false)
    }

    return () => {
      console.log("Closing SSE connection")
      eventSource.close()
    }
  }, [])

  return { vcStates, isConnected }
}

