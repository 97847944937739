// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the Location card */
.location-card {
    margin-bottom: 20px;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.location-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

.location-card .card-body {
    padding: 20px;
}

.location-card .card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.location-card .card-text div {
    margin-bottom: 0.5rem;
}

.location-card .btn {
    min-width: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/location.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;IACI,mBAAmB;IACnB,kBAAkB;IAClB,qDAAqD;AACzD;;AAEA;IACI,sBAAsB;IACtB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["/* Custom styles for the Location card */\n.location-card {\n    margin-bottom: 20px;\n    border-radius: 8px;\n    transition: transform 0.2s ease, box-shadow 0.2s ease;\n}\n\n.location-card:hover {\n    transform: scale(1.02);\n    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);\n}\n\n.location-card .card-body {\n    padding: 20px;\n}\n\n.location-card .card-title {\n    font-size: 1.25rem;\n    font-weight: bold;\n}\n\n.location-card .card-text div {\n    margin-bottom: 0.5rem;\n}\n\n.location-card .btn {\n    min-width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
