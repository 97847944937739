import React, { createContext, useContext, useState} from 'react';

interface ThemeContextType {
  primaryColor: string;
  logoUrl: string;
  updateTheme: (color: string, logo: string) => void;
}

const ThemeContext = createContext<ThemeContextType>({
  primaryColor: 'linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%)',
  logoUrl: '',
  updateTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: React.ReactNode;
  initialColor?: string;
  initialLogo?: string;
}

export const ThemeProvider = ({ children, initialColor = 'linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%)', initialLogo = '' }: ThemeProviderProps) => {
  const [primaryColor, setPrimaryColor] = useState(initialColor);
  const [logoUrl, setLogoUrl] = useState(initialLogo);

  const updateTheme = (color: string, logo: string) => {
    setPrimaryColor(color);
    setLogoUrl(logo);
  };

  return (
    <ThemeContext.Provider value={{ primaryColor, logoUrl, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};