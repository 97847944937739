import { Visitor } from "../models/visitor";

//fetch the data
async function fetchData(input: string, init?: RequestInit) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}${input}`, {
        ...init,
        credentials: 'include',
    });

    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error || 'An error occurred';
        console.error(`Fetch error: ${errorMessage}`);
        throw new Error(errorMessage);
    }
}

// Fetch all visitors for a specific user
export async function fetchVisitors(accessToken: string) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/visitors`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json', // Ensures JSON handling
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(`Failed to fetch visitors: ${errorBody.error || response.statusText}`);
    }
    return response.json();
}

// Visitor input interface
export interface VisitorInput {
    firstName: string;
    lastName: string;
    email: string;
    description?: string;
}

// Create a new visitor
export async function createVisitor(visitor: VisitorInput, accessToken: string): Promise<Visitor> {
    const response = await fetchData("/visitors", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`, // Authorization header
        },
        body: JSON.stringify(visitor),
    });
    return response.json();
}

// Update an existing visitor
export async function updateVisitor(visitorId: string, visitor: VisitorInput, accessToken: string): Promise<Visitor> {
    const response = await fetchData(`/visitors/${visitorId}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`, // Authorization header
        },
        body: JSON.stringify(visitor),
    });

    if (!response.ok) {
        const errorBody = await response.json();
        console.error(`Update error: ${errorBody.error || response.statusText}`);
        throw new Error(`Error updating visitor: ${errorBody.error || response.statusText}`);
    }

    return response.json();
}

// Delete a visitor
export const deleteVisitor = async (visitorId: string, accessToken: string) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/visitors/${visitorId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${accessToken}`, // Authorization header
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    console.log(response);

};
