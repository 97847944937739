"use client"

import type React from "react"
import { useState, useEffect, useRef } from "react"
import * as LogoAPI from "../../network/logo_api"
import { useTheme } from "../ContextTheme"
import "../../styles/settingsPage.css"

// Theme modes
// Replace THEME_MODES array in SettingsPage component
const THEME_MODES = [
  { id: "light", label: "Light Mode" },
  { id: "dark", label: "Dark Mode" },
  { id: "nexus", label: "Nexus Mode" },
];

// Improved color palettes with better visual harmony
const COLOR_PALETTES = {
  primary: [
    "#1a1a2e", // Navy
    "#2a2a5a", // Royal Blue
    "#3a3a78", // Indigo
    "#4a4a96", // Purple
    "#2d3e50", // Deep Blue
    "#16213e", // Midnight Blue
  ],
  secondary: [
    "#ffd32a", // Sunshine Yellow
    "#f39c12", // Amber
    "#ff6b6b", // Coral
    "#4cd137", // Lime Green
    "#00d2d3", // Turquoise
    "#9b59b6", // Lavender
  ],
}

// Color combinations that work well together
const RECOMMENDED_PAIRS = [
  { primary: "#1a1a2e", secondary: "#ffd32a" }, // Navy & Yellow
  { primary: "#2a2a5a", secondary: "#00d2d3" }, // Royal Blue & Turquoise
  { primary: "#16213e", secondary: "#f39c12" }, // Midnight Blue & Amber
  { primary: "#4a4a96", secondary: "#ff6b6b" }, // Purple & Coral
  { primary: "#2d3e50", secondary: "#4cd137" }, // Deep Blue & Lime Green
  { primary: "#3a3a78", secondary: "#9b59b6" }, // Indigo & Lavender
]

interface SettingPageProps {
  accessToken: string
}

export default function SettingsPage({ accessToken }: SettingPageProps) {
  const { primaryColor: contextPrimaryColor, logoUrl: contextLogoUrl, updateTheme } = useTheme()
  const [themeMode, setThemeMode] = useState(() => 
    localStorage.getItem("themeMode") || "light"
  )
  const [primaryColor, setPrimaryColor] = useState(() => localStorage.getItem("primaryColor") || contextPrimaryColor)
  const [secondaryColor, setSecondaryColor] = useState(() => localStorage.getItem("secondaryColor") || "#ffd32a")
  const [logoUrl, setLogoUrl] = useState(() => localStorage.getItem("logoUrl") || contextLogoUrl)
  const [logoId, setLogoId] = useState(localStorage.getItem("logoId") || "")
  const fileInputRef = useRef<HTMLInputElement>(null)

  // Initialize logo preview from logoUrl
  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", primaryColor)
    document.documentElement.style.setProperty("--secondary-color", secondaryColor)

    localStorage.setItem("themeMode", themeMode)
    localStorage.setItem("primaryColor", primaryColor)
    localStorage.setItem("secondaryColor", secondaryColor)

    if (logoUrl) {
      localStorage.setItem("logoUrl", logoUrl)
    } else {
      localStorage.removeItem("logoUrl")
    }

    updateTheme(primaryColor, logoUrl, secondaryColor, themeMode)

    // Save settings whenever they change
    const saveSettings = async () => {
      if (logoId) {
        const formData = new FormData()
        formData.append("primaryColor", primaryColor)
        formData.append("secondaryColor", secondaryColor)
        formData.append("themeMode", themeMode)

        try {
          await LogoAPI.updateLogo(logoId, formData, accessToken)
        } catch (error) {
          console.error("Error saving settings:", error)
        }
      }
    }

    saveSettings()
  }, [themeMode, primaryColor, secondaryColor, logoUrl, updateTheme, logoId, accessToken])

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    if (file.size > 5 * 1024 * 1024) {
      alert("File is too large. Maximum size is 5MB.")
      return
    }

    const formData = new FormData()
    formData.append("logo", file)
    formData.append("primaryColor", primaryColor)
    formData.append("secondaryColor", secondaryColor)
    formData.append("themeMode", themeMode)

    try {
      let data
      if (logoId) {
        data = await LogoAPI.updateLogo(logoId, formData, accessToken)
      } else {
        data = await LogoAPI.createLogo(formData, accessToken)
      }

      if (typeof data.logo === "string" && data._id) {
        setLogoUrl(data.logo)
        setLogoId(data._id)
        localStorage.setItem("logoId", data._id)
      }
    } catch (error) {
      console.error("Error uploading logo:", error)
    }
  }

  const resetTheme = () => {
    setThemeMode("light");
    setPrimaryColor("#1a1a2e");
    setSecondaryColor("#ffd32a");
    localStorage.removeItem("themeMode");
  };

  const applyColorPair = (primary: string, secondary: string) => {
    setPrimaryColor(primary)
    setSecondaryColor(secondary)
  }

  return (
    <div className="settings-container">
      <div className="settings-card">
        <h1 className="settings-title" style={{ color: secondaryColor }}>Theme Settings</h1>

        {/* Logo Preview */}
        <div className="logo-preview-container" style={{ backgroundColor: primaryColor }}>
          {logoUrl ? (
            <img src={`data:image/png;base64, ${logoUrl}`} alt="Logo Preview" className="logo-preview" />
          ) : (
            <div className="no-logo-placeholder">
              <span style={{ color: secondaryColor }}>Upload a logo</span>
            </div>
          )}
        </div>

        {/* Logo Upload */}
        <div className="logo-upload">
          <button 
            className="upload-button"
            onClick={() => fileInputRef.current?.click()}
            style={{ backgroundColor: secondaryColor, color: primaryColor }}
          >
            {logoUrl ? "Change Logo" : "Upload Logo"}
          </button>
          <input 
            ref={fileInputRef} 
            type="file" 
            accept="image/*" 
            onChange={handleLogoUpload} 
            className="file-input" 
            hidden 
          />
        </div>

        {/* Theme Mode Selection */}
        <div className="settings-section">
          <h2 className="section-title" style={{ color: secondaryColor }}>Theme Mode</h2>
          <div className="theme-mode-container">
          {THEME_MODES.map((mode) => (
            <button
            key={mode.id}
            onClick={() => setThemeMode(mode.id)}
            className={`theme-mode-button ${themeMode === mode.id ? "active" : ""}`}
            style={{ 
              borderColor: secondaryColor,
              backgroundColor: themeMode === mode.id ? secondaryColor : "transparent",
              color: themeMode === mode.id ? primaryColor : secondaryColor
            }}
          >
            {mode.label}
          </button>
          ))}
          </div>
        </div>

        {/* Recommended Color Combinations */}
        <div className="settings-section">
          <h2 className="section-title" style={{ color: secondaryColor }}>Recommended Color Pairs</h2>
          <div className="color-pairs-container">
            {RECOMMENDED_PAIRS.map((pair, index) => (
              <div 
                key={index} 
                className="color-pair"
                onClick={() => applyColorPair(pair.primary, pair.secondary)}
                style={{ 
                  borderColor: pair.primary === primaryColor && pair.secondary === secondaryColor ? secondaryColor : "transparent"
                }}
              >
                <div className="color-sample" style={{ backgroundColor: pair.primary }}></div>
                <div className="color-sample" style={{ backgroundColor: pair.secondary }}></div>
              </div>
            ))}
          </div>
        </div>

        {/* Primary Colors */}
        <div className="settings-section">
          <h2 className="section-title" style={{ color: secondaryColor }}>Primary Color (Navbar/Banner)</h2>
          <div className="color-palette">
            {COLOR_PALETTES.primary.map((color) => (
              <button
                key={color}
                onClick={() => setPrimaryColor(color)}
                className={`color-option ${primaryColor === color ? "selected" : ""}`}
                style={{ 
                  backgroundColor: color,
                  borderColor: primaryColor === color ? secondaryColor : "transparent"
                }}
              />
            ))}
          </div>
        </div>

        {/* Secondary Colors */}
        <div className="settings-section">
          <h2 className="section-title" style={{ color: secondaryColor }}>Secondary Color (Text/Accents)</h2>
          <div className="color-palette">
            {COLOR_PALETTES.secondary.map((color) => (
              <button
                key={color}
                onClick={() => setSecondaryColor(color)}
                className={`color-option ${secondaryColor === color ? "selected" : ""}`}
                style={{ 
                  backgroundColor: color,
                  borderColor: secondaryColor === color ? primaryColor : "transparent"
                }}
              />
            ))}
          </div>
        </div>

        {/* Reset Button */}
        <button 
          onClick={resetTheme} 
          className="reset-button"
          style={{ backgroundColor: "#ff6b6b", color: "#ffffff" }}
        >
          Reset to Default
        </button>
      </div>
    </div>
  )
}