import React from 'react';
import styles from "../styles/Visitor.module.css";
import { Visitor as VisitorModel } from "../models/visitor";
import { formatDate } from "../utils/formatDate";
import { MdDelete, MdEdit } from "react-icons/md";

interface VisitorProps {
  visitor: VisitorModel;
  onVisitorClicked: (visitor: VisitorModel) => void;
  onDeleteVisitorClicked: (visitor: VisitorModel) => void;
  onEditVisitorClicked: (visitor: VisitorModel) => void;
  className?: string;
}

const Visitor = ({ visitor, onVisitorClicked, onDeleteVisitorClicked, onEditVisitorClicked, className }: VisitorProps) => {
  const { firstName, lastName, email, description, createdAt, updatedAt, imageUrl } = visitor;

  const createdUpdateText = updatedAt > createdAt
    ? "Updated: " + formatDate(updatedAt)
    : "Created: " + formatDate(createdAt);

  return (
    <div 
      className={`${styles.visitorCard} ${className}`}
      onClick={() => onVisitorClicked(visitor)}
    >
      <div className={styles.cardContent}>
        <div className={styles.avatarContainer}>
          {imageUrl ? (
            <img src={imageUrl} alt={`${firstName} ${lastName}`} className={styles.visitorImage} />
          ) : (
            <div className={styles.defaultIconContainer}>
            </div>
          )}
        </div>

        <div className={styles.cardBody}>
          <div className={styles.cardHeader}>
            <h5 className={styles.nameTitle}>{firstName} {lastName}</h5>
            <div className={styles.iconContainer}>
              <MdEdit
                className={styles.icon}
                style={{ color: '#0D6EFD' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onEditVisitorClicked(visitor);
                }}
              />
              <MdDelete
                className={styles.icon}
                style={{ color: '#DC3545' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteVisitorClicked(visitor);
                }}
              />
            </div>
          </div>
          
          <a 
            href={`mailto:${email}`}
            onClick={(e) => e.stopPropagation()}
            className={styles.emailLink}
          >
            {email}
          </a>

          <div className={styles.descriptionContainer}>
            <p className={styles.description}>
              {description}
            </p>
            <div className={styles.fadeOut} />
          </div>
        </div>
      </div>

      <div className={styles.cardFooter}>
        {createdUpdateText}
      </div>
    </div>
  );
};

export default Visitor;
