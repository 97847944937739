import { Logo } from "../models/logo";

// Fetch helper function
async function fetchData(input: string, init?: RequestInit) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}${input}`, {
        ...init,
        credentials: 'include',
    });

    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error || 'An error occurred';
        console.error(`Fetch error: ${errorMessage}`);
        throw new Error(errorMessage);
    }
}

// Fetch all logos for a specific user
export async function fetchLogos(accessToken: string) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/logo`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(`Failed to fetch logos: ${errorBody.error || response.statusText}`);
    }
    return response.json();
}

// Update the LogoInput interface if it is used elsewhere
export interface LogoInput {
    themeMode?: string;
    primaryColor?: string;
    secondaryColor?: string;
}

// Create a new logo
export async function createLogo(logoData: FormData, accessToken: string): Promise<Logo> {
    const response = await fetchData("/logo", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        body: logoData, // FormData automatically sets Content-Type
    });
    return response.json();
}

// Update an existing logo
export async function updateLogo(logoId: string, logoData: FormData, accessToken: string): Promise<Logo> {
    const response = await fetchData(`/logo/${logoId}`, {
        method: "PATCH",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
        body: logoData,
    });

    if (!response.ok) {
        const errorBody = await response.json();
        console.error(`Update error: ${errorBody.error || response.statusText}`);
        throw new Error(`Error updating logo: ${errorBody.error || response.statusText}`);
    }

    return response.json();
}

// Delete a logo
export const deleteLogo = async (logoId: string, accessToken: string) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/logo/${logoId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(`Failed to delete logo: ${errorBody.error || response.statusText}`);
    }
};
