import React, { useState } from "react";
import { Button, Form, Modal, ModalHeader, ModalTitle, Row, Col } from "react-bootstrap";
import { Location } from "../models/location";
import { useForm, useFieldArray } from "react-hook-form";
import { LocationInput } from "../network/location_api";
import * as LocationApi from "../network/location_api";
import TextInputField from "./form/TextInputField";
import { MdAdd, MdDelete } from "react-icons/md";
import LocationMap from "./LocationMap";

interface AddEditLocationDialogProps {
  accessToken: string;
  locationToEdit?: Location;
  onDismiss: () => void;
  onLocationSaved: (location: Location) => void;
}
interface LocationFormInput extends LocationInput {
  lat: number;
  lng: number;
  rooms: { value: string }[];
}
const AddEditLocationDialog = ({
  accessToken,
  locationToEdit,
  onDismiss,
  onLocationSaved,
}: AddEditLocationDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
  } = useForm<LocationFormInput>({
    defaultValues: {
      name: locationToEdit?.name || "",
      address: locationToEdit?.address || "",
      rooms: locationToEdit?.room
        ? Array.isArray(locationToEdit.room)
          ? locationToEdit.room.map((r) => ({ value: r }))
          : [{ value: locationToEdit.room }]
        : [{ value: "" }],
      description: locationToEdit?.description || "",
      lat: locationToEdit?.lat || 0,
      lng: locationToEdit?.lng || 0,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rooms",
  });

  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>({
    lat: locationToEdit?.lat || 0,
    lng: locationToEdit?.lng || 0,
  });

  // Handle location selection from map
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLocationSelect = (
    lat: number, 
    lng: number, 
    address?: string
  ) => {
    // Update coordinates
    setLatLng({ lat, lng });
    
    // Update form values
    setValue('lat', lat);
    setValue('lng', lng);
    
    // Optionally update address if provided
    if (address) {
      setValue('address', address);
    }
  };

  async function onSubmit(input: LocationFormInput) {
    try {
      const locationInput: LocationInput = {
        ...input,
        lat: latLng.lat,
        lng: latLng.lng,
        room: input.rooms.map((r) => r.value),
      };
  
      let locationResponse: Location;
      
      if (locationToEdit) {
        // Update existing location
        locationResponse = await LocationApi.updateLocation(
          locationToEdit._id,
          locationInput,
          accessToken
        );
      } else {
        // Create new location
        locationResponse = await LocationApi.createLocation(locationInput);
      }
      
      onLocationSaved(locationResponse);
    } catch (error) {
      console.error(error);
      alert("An error occurred while saving the location. Please try again.");
    }
  }

  return (
    <Modal show onHide={onDismiss} size="lg">
      <ModalHeader closeButton>
        <ModalTitle>
          {locationToEdit ? "Edit location" : "Add location"}
        </ModalTitle>
      </ModalHeader>
      <Modal.Body>
        <Form id="addEditLocationForm" onSubmit={handleSubmit(onSubmit)}>
          <TextInputField
            name="name"
            label="Location Name"
            type="text"
            placeholder="Sandman hotel"
            register={register}
            registerOptions={{ required: "Required" }}
            error={errors.name}
          />
          <TextInputField
            name="address"
            label="Address"
            type="text"
            placeholder="123 Coastal Drive, Beach City, BC V8V 1A1"
            register={register}
            registerOptions={{ required: "Required" }}
            error={errors.address}
          />

          {/* Hidden fields for lat and lng */}
          <input 
            type="hidden" 
            {...register('lat')} 
          />
          <input 
            type="hidden" 
            {...register('lng')} 
          />

          <TextInputField
            name="description"
            label="Description"
            as="textarea"
            rows={5}
            placeholder="Description"
            register={register}
          />

          <div className="mb-3">
            <label className="form-label">Rooms</label>
            {fields.map((field, index) => (
              <Row key={field.id} className="mb-2 align-items-center">
                <Col>
                  <Form.Control
                    {...register(`rooms.${index}.value`)}
                    placeholder="Enter room name/number"
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => fields.length > 1 && remove(index)}
                  >
                    <MdDelete />
                  </Button>
                </Col>
              </Row>
            ))}
            <Button
              variant="outline-secondary"
              size="sm"
              className="mt-2"
              onClick={() => append({ value: "" })}
            >
              <MdAdd className="me-1" /> Add Room
            </Button>
          </div>
    

          {/* Google Map */}
          {<div className="mb-3">
            <label className="form-label">Location on Map</label>
            <LocationMap
              lat={latLng.lat}
              lng={latLng.lng}
              onLocationSelect={handleLocationSelect}
            />
          </div> }

          <div className="d-flex justify-content-end">
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditLocationDialog;