import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Location as LocationModel } from "../models/location";

interface LocationsMapProps {
  locations: LocationModel[];
  onLocationClick: (location: LocationModel) => void;
}

const LocationsMap = ({ locations, onLocationClick }: LocationsMapProps) => {
  const DefaultIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  const validLocations = locations.filter(loc => loc.lat && loc.lng);
  
  // Explicitly type the center coordinates
  const center: L.LatLngTuple = validLocations.length > 0 ? 
    [validLocations[0].lat!, validLocations[0].lng!] : 
    [40.712776, -74.005974];

  return (
    <MapContainer
      center={center}
      zoom={13}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {validLocations.map((location) => (
        <Marker
          key={location._id}
          position={[location.lat!, location.lng!]}
          icon={DefaultIcon}
          eventHandlers={{
            click: () => onLocationClick(location),
          }}
        />
      ))}
    </MapContainer>
  );
};

export default LocationsMap;