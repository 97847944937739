// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Navbar styles */
.custom-navbar {
    background: linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%);
    padding: 1rem 2rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.custom-navbar .navbar-brand {
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
}

.nav-item {
    font-size: 1.1rem;
    color: #f8f9fa !important;
    margin-right: 1rem;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-item:hover {
    color: #ffc107 !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.nav-item .dropdown-item {
    color: #212529 !important;
}

.nav-item .dropdown-item:hover {
    background-color: #ffc107 !important;
    color: #fff !important;
}

@media (max-width: 768px) {
    .nav-item {
        font-size: 1rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,6EAA6E;IAC7E,kBAAkB;IAClB,2CAA2C;IAC3C,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,uDAAuD;AAC3D;;AAEA;IACI,yBAAyB;IACzB,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["/* Custom Navbar styles */\n.custom-navbar {\n    background: linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,90,160,1) 100%);\n    padding: 1rem 2rem;\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n    transition: all 0.3s ease;\n}\n\n.custom-navbar .navbar-brand {\n    font-size: 1.5rem;\n    letter-spacing: 0.05rem;\n}\n\n.nav-item {\n    font-size: 1.1rem;\n    color: #f8f9fa !important;\n    margin-right: 1rem;\n    transition: color 0.3s ease, background-color 0.3s ease;\n}\n\n.nav-item:hover {\n    color: #ffc107 !important;\n    background-color: rgba(255, 255, 255, 0.1);\n    border-radius: 5px;\n}\n\n.nav-item .dropdown-item {\n    color: #212529 !important;\n}\n\n.nav-item .dropdown-item:hover {\n    background-color: #ffc107 !important;\n    color: #fff !important;\n}\n\n@media (max-width: 768px) {\n    .nav-item {\n        font-size: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
